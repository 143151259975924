import { yupResolver } from '@hookform/resolvers/yup';
import { WebAuth } from 'auth0-js';
import axios from 'axios';
import ls from 'localstorage-slim';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import config from '../../../../config';
import { setNotification } from '../../../../redux/cache-slice';
import { getText } from '../../../../utils/getter';
import { errorLogging } from '../../../../utils/logging';
import Checkbox from '../../../b2b/components/checkbox/checkbox';
import ButtonGroup from '../../../b2b/molecules/button-group/button-group';
import InputField from '../../input-field/input-field';
import './register-form.css';

export type onSubmit = (data: Record<string, unknown>) => void;

interface RegisterFormProps {
  onSubmit?: onSubmit;
}

type AllowedValidationValues =
  | 'symbol'
  | 'eight'
  | 'capital'
  | 'small'
  | 'digit';

const RegisterForm: React.FC<RegisterFormProps> = ({
  onSubmit,
}: RegisterFormProps) => {
  const validationArray: AllowedValidationValues[] = [];
  // Dispatch hook
  const dispatch = useDispatch();

  // Loading State
  const [loading, setLoading] = useState<boolean>();

  const [passwordTip, setPasswordTip] = useState<any>();

  // Auth0 library config
  const auth = new WebAuth({
    domain: config.AUTH0_DOMAIN,
    clientID: config.AUTH0_CLIENT_ID,
    redirectUri: `${window.location.origin}/callback`,
    audience: config.AUTH0_AUDIENCE,
    responseType: 'id_token token',
  });

  // Register user function
  const registerUser = async (data: {
    email: string;
    password: string;
    givenName: string;
    familyName: string;
    accept: boolean;
  }): Promise<void> => {
    setLoading(true);
    if (onSubmit) onSubmit(data);
    const { email, password, givenName, familyName } = data;
    const offer = ls.get<{ offerId: string; partner: string }>('oid');
    if (offer !== null && offer !== undefined) {
      const { offerId, partner } = offer;
      axios
        .post('/api/lease-application/signup', {
          email,
          password,
          username: `${givenName}-${familyName}`,
          firstName: givenName,
          lastName: familyName,
          offerId,
          partner: partner.toUpperCase(),
        })
        .then(() => {
          setLoading(false);
          auth.login(
            {
              realm: config.AUTH0_ALLOWED_CONNECTIONS,
              email,
              password,
            },
            (e: any) => {
              dispatch(
                setNotification({
                  notificationVisibility: true,
                  notificationHasError: true,
                  notificationTitle: 'Es ist ein Fehler aufgetreten.',
                  notificationBody: getText('error_happened'),
                }),
              );
              console.log('Login Error ', e);
              errorLogging(e);
            },
          );
        })
        .catch((e) => {
          setLoading(false);
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Es ist ein Fehler aufgetreten.',
              notificationBody: getText('error_happened'),
            }),
          );
        });
    } else {
      setLoading(false);
      dispatch(
        setNotification({
          notificationVisibility: true,
          notificationHasError: true,
          notificationTitle: 'Es ist ein Fehler aufgetreten.',
          notificationBody: getText('error_happened'),
        }),
      );
    }
  };

  // Form validation
  const validationSchema = useMemo(
    () =>
      yup.object({
        givenName: yup.string().required(config.REQUIRED_VALIDATION),
        familyName: yup.string().required(config.REQUIRED_VALIDATION),
        email: yup
          .string()
          .email(config.EMAIL_VALIDATION)
          .required(config.REQUIRED_VALIDATION),
        password: yup
          .string()
          .required(config.REQUIRED_VALIDATION)
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#~;+]{8,}$/,
            config.PASSWORD_VALIDATION,
          ),
        accept: yup
          .boolean()
          .required(config.REQUIRED_VALIDATION)
          .oneOf([true], config.REQUIRED_VALIDATION),
      }),
    [],
  );

  // useForm Hook
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
  } = useForm<{
    email: string;
    password: string;
    givenName: string;
    familyName: string;
    accept: boolean;
  }>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  });

  const vaildatePassword = (password: string) => {
    setValue('password', password);
    trigger('password');
    const symbols = /(?=.*[@$!%*?&#])[@$!%*?&#~;+]/;
    const capitalLetter = /(?=.*[A-Z])[A-Z]/;
    const smallLetter = /(?=.*[a-z])[a-z]/;
    const digit = /\d/;

    if (symbols.exec(password)) {
      validationArray.push('symbol');
    }
    if (capitalLetter.exec(password)) {
      validationArray.push('capital');
    }
    if (smallLetter.exec(password)) {
      validationArray.push('small');
    }
    if (digit.exec(password)) {
      validationArray.push('digit');
    }
    if (password.length >= 8) {
      validationArray.push('eight');
    }

    setPasswordTip(
      <p className='small-text bold'>
        <span className={validationArray.includes('eight') ? 'green' : 'red'}>
          mindestens 8 Zeichen lang,
        </span>{' '}
        <span className={validationArray.includes('capital') ? 'green' : 'red'}>
          einen Großbuchstaben,
        </span>{' '}
        <span className={validationArray.includes('small') ? 'green' : 'red'}>
          einen Kleinbuchstaben,
        </span>{' '}
        <span className={validationArray.includes('digit') ? 'green' : 'red'}>
          eine Zahl
        </span>{' '}
        <span className={validationArray.includes('symbol') ? 'green' : 'red'}>
          und ein Sonderzeichen (z.B. !, @, #, $)
        </span>
      </p>,
    );
  };

  return (
    <form
      className='auth-form-content login'
      data-testid='form'
      onSubmit={handleSubmit((data) => registerUser(data))}
    >
      <div className='auth-form'>
        <div className='input-container'>
          <InputField
            className='row-input-field'
            type='text'
            {...register('givenName')}
            label='Vorname(n)'
            error={errors.givenName?.message}
          />{' '}
        </div>
        <div className='input-container'>
          <InputField
            className='row-input-field'
            type='text'
            {...register('familyName')}
            label='Nachname(n)'
            error={errors.familyName?.message}
          />
        </div>
        <div className='input-container'>
          <InputField
            className='row-input-field'
            type='email'
            {...register('email')}
            label='E-Mail-Adresse'
            error={errors.email?.message}
          />
        </div>
        <div className='input-container'>
          <InputField
            className='row-input-field'
            type='password'
            {...register('password')}
            data-testid='pass-test'
            label='Passwort'
            onChange={(e) => vaildatePassword(e.target.value)}
            error={errors.password?.message}
            withErrorMessage={false}
          />
          {passwordTip}
        </div>

        <div className='input-container'>
          <div className='checkboxes-container square'>
            <Checkbox
              {...register('accept')}
              square
              type='checkbox'
              checkboxPosition='top'
              style={{ alignSelf: 'baseline', marginLeft: '15px' }}
              errorMessage={errors?.accept?.message}
            >
              <span className='left small-text' data-testid='terms'>
                Ich habe die{' '}
                <a
                  data-testid='datenschutz'
                  href='https://www.ald-online.de/pages/datenschutzhinweise'
                  target='_blank'
                  rel='noreferrer'
                >
                  <b>Datenschutzhinweise </b>
                </a>
                zur Kenntnis genommen
              </span>
            </Checkbox>
          </div>
        </div>
      </div>

      <div className='center' style={{ width: '100%', marginTop: '32px' }}>
        <ButtonGroup
          className='back-next full'
          type='default'
          buttonOneProps={{
            type: 'submit',
            loading,
            className: 'button-mobile',
            name: 'form-submit',
            dataTestId: 'submit-button',
            children: 'Jetzt registrieren',
          }}
        />
      </div>
    </form>
  );
};

export default RegisterForm;
