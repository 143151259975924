import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { EDevice } from '../../../redux/sequence/enums';
import { RootState } from '../../../redux/types';
import Button from '../../b2b/components/button/button';
import PartnerLogo from '../../b2b/components/partner-logo/partner-logo';
import CouplePhoto from '../images/overview-photo.jpg';

const NoOffer = () => {
  const { device } = useSelector((state: RootState) => state.sequence);
  return (
    <>
      <div className='left container'>
        <PartnerLogo />
      </div>
      <div className='auth-container'>
        <div
          className='auth-form-container login'
          style={{ paddingTop: device === EDevice.DESKTOP ? '256px' : '' }}
        >
          <div className='auth-form-content login'>
            <div>
              <h1 className='title no-offer left'>
                Herzlich Willkommen
                <br />
                bei ALD Automotive
              </h1>
              <h3 className='content-bold left'>
                Du hast derzeit kein Fahrzeug ausgewählt.
              </h3>
              <p className='content-normal dark-grey'>
                Um dich auf unserer Seite registrieren zu können, benötigst du
                ein Fahrzeug. Schau dich gerne auf unserer Webseite um und finde
                das passendes Modell für dich.
              </p>
            </div>
            <Button
              onClick={() => window.open('https://www.ald-online.de/')}
              type='button'
              className='button-text'
              style={{ marginTop: '48px' }}
            >
              Weiter zu ALD-Online.de
            </Button>
            <p
              className='content-normal dark-grey'
              style={{ marginTop: '24px' }}
            >
              Du hast bereits einen Account? Dann{' '}
              <Link to='/login' className='bold'>
                jetzt anmelden.
              </Link>
            </p>
          </div>
        </div>
        <div className='auth-image-container login'>
          <div
            className='container-background'
            style={{ backgroundImage: `url(${CouplePhoto})` }}
          />
          <div className='auth-image-content register '>
            <div className='center' style={{ width: '100%' }}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoOffer;
