import LayerOne from '../../../../styles/animated/lease-application-loader/Layer_1.svg';
import LayerTwo from '../../../../styles/animated/lease-application-loader/Layer_2.svg';
import LayerThree from '../../../../styles/animated/lease-application-loader/Layer_3.svg';
import LayerFour from '../../../../styles/animated/lease-application-loader/Layer_4.svg';
import './lease-application-loader.css';

const LeaseApplicationLoader = () => {
  return (
    <div className='animation-layout margin-auto'>
      <img src={LayerOne} alt='animation' className='animate' />
      <img src={LayerTwo} alt='animation' className='animate' />
      <img src={LayerTwo} alt='animation' className='animate' />
      <img src={LayerThree} alt='animation' className='animate' />
      <img src={LayerFour} alt='animation' className='animate' />
    </div>
  );
};

export default LeaseApplicationLoader;
