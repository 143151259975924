import { unwrapResult } from '@reduxjs/toolkit';
import ls from 'localstorage-slim';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  deleteSavedUserOffer,
  getSavedUserOffer,
  saveUserOffer,
} from '../api/instant-lease-api';
import { isAuthenticated } from '../redux/auth/authUtils';
import { setLoaderModal, setNotification } from '../redux/cache-slice';
import {
  createLeaseApp,
  fetchLeaseApps,
} from '../redux/leaseApp/lease-app-slice';
import store, { RootState, useAppDispatch } from '../redux/store';
import { LeaseApplication, Offer, Partner } from '../types/instant-lease-api';
import { getText } from '../utils/getter';
import { clearTimer } from '../utils/helper';

interface SavedUserOffer {
  data: {
    message: string;
    timestamp: string;
    status: string;
    user_details: Offer[];
  };
}

const useOfferCreation = (): void => {
  // Auth State
  const auth = useSelector((state: RootState) => state.auth);

  // Cache State
  const { hasNewOffer } = useSelector((state: RootState) => state.cache);

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Offer data from localstorage if exist
  const [offer, setOffer] = useState<Offer | null>(ls.get<Offer>('oid'));

  useEffect(() => {
    if (ls.get<Offer>('oid') !== null) {
      setOffer(ls.get<Offer>('oid'));
    }
  }, [hasNewOffer]);

  useEffect(() => {
    // Loader Modal
    dispatch(
      setLoaderModal({
        visibility: true,
        text: 'Lade Fahrzeug- und Antragsdaten...',
      }),
    );

    // Get active lease application state from store
    const activeApplication = store.getState().leaseApp?.activeApplication;

    // Fetch lease applications
    const getLeaseApplications = () => {
      return new Promise<string>((resolve, reject) => {
        if (!activeApplication) {
          return dispatch(fetchLeaseApps({ accessToken: auth.accessToken }))
            .then(() => resolve('Getting Leaseing Application Fulfilled'))
            .catch(() => reject());
        }
        return resolve('Getting Lease Application Fulfilled');
      });
    };

    // Create new lease application
    const createNewLeasingApplication = () => {
      return new Promise<string>((resolve, reject) => {
        ls.remove('bo');
        ls.remove('ps');
        ls.remove('tr');
        ls.remove('loa');
        const defaultPartner = offer?.partner ? offer?.partner : Partner.ALD;
        if (offer?.offerId && offer?.offerId !== 'null' && defaultPartner) {
          return dispatch(
            createLeaseApp({
              accessToken: auth.accessToken,
              partner: defaultPartner,
              offerId: offer?.offerId,
              pricingId: offer?.pricingId,
              publicationId: offer?.publicationId,
              type: offer?.type,
              vin: offer?.vin,
              loyaltyId: offer?.loyaltyId,
              loyaltyType: offer?.loyaltyType,
              marketingData: offer?.marketingData,
            }),
          )
            .then(unwrapResult)
            .then(() => {
              ls.remove('oid');
              ls.remove('pcms');
              setOffer(null);
              deleteSavedUserOffer({
                accessToken: auth.accessToken,
                email: auth?.email,
                offerId: offer?.offerId,
              });
              const fetchLeaseAppsTimer = setInterval(async () => {
                return dispatch(
                  fetchLeaseApps({ accessToken: auth.accessToken }),
                )
                  .then(unwrapResult)
                  .then((response) => {
                    const leaseApps = response as LeaseApplication[];
                    if (leaseApps?.length > 0) {
                      clearTimer(fetchLeaseAppsTimer);
                    }
                    return resolve(
                      'Creating a New Leasing Application Fulfilled',
                    );
                  })
                  .catch(() => {
                    ls.remove('carData');
                    dispatch(
                      setNotification({
                        notificationVisibility: true,
                        notificationHasError: true,
                        notificationTitle: 'Das tut uns leid.',
                        notificationBody: getText(
                          'cannot_create_lease_application',
                        ),
                      }),
                    );
                    return reject();
                  });
              }, 4000);

              setTimeout(() => {
                clearTimer(fetchLeaseAppsTimer);
                return reject();
              }, 7500);
            })
            .catch((error) => {
              ls.remove('oid');
              setOffer(null);
              deleteSavedUserOffer({
                accessToken: auth.accessToken,
                email: auth?.email,
                offerId: offer?.offerId,
              }).then(() => {
                dispatch(
                  setNotification({
                    notificationVisibility: true,
                    notificationHasError: true,
                    notificationTitle: 'Das tut uns leid.',
                    notificationBody: getText(
                      'cannot_create_lease_application',
                    ),
                  }),
                );
                return reject(`error creating leasing application ${error}`);
              });
            });
        }

        return false;
      });
    };

    // Promises Array
    const promises: Promise<string>[] = [];

    if (isAuthenticated(auth)) {
      if (auth.emailVerified === true) {
        if (offer) {
          promises.push(createNewLeasingApplication());
        }

        if (!offer) {
          getSavedUserOffer({
            accessToken: auth.accessToken,
            email: auth?.email,
          })
            .then(({ data }: SavedUserOffer) => {
              if (data.user_details.length > 0) {
                setOffer({
                  offerId: data.user_details[0]?.offerId,
                  publicationId: data.user_details[0]?.publicationId,
                  pricingId: data.user_details[0]?.pricingId,
                  vin: data.user_details[0]?.vin,
                  partner: data.user_details[0]?.partner,
                });
                deleteSavedUserOffer({
                  accessToken: auth.accessToken,
                  email: auth?.email,
                  offerId: data.user_details[0]?.offerId,
                });
              }
            })
            .catch(() => console.log('error'));
        }

        if (!activeApplication) {
          promises.push(getLeaseApplications());
        }
      }

      if (auth.emailVerified === false && offer) {
        saveUserOffer({
          accessToken: auth.accessToken,
          offerId: offer?.offerId || '',
          email: auth?.email,
          pricingId: offer?.pricingId,
          publicationId: offer?.publicationId,
          vin: offer?.vin,
          partner: offer?.partner as any,
        }).catch(() => console.log('error'));
      }
    }

    if (promises.length > 0) {
      Promise.all(promises)
        .then(() => {
          setTimeout(() => {
            dispatch(setLoaderModal({ visibility: false, text: '' }));
          }, 4000);
        })
        .catch(() => {
          setTimeout(() => {
            dispatch(setLoaderModal({ visibility: false, text: '' }));
          }, 4000);
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
        });
    } else {
      setTimeout(() => {
        dispatch(setLoaderModal({ visibility: false, text: '' }));
      }, 4000);
    }

    return () => {
      setTimeout(() => {
        dispatch(setLoaderModal({ visibility: false, text: '' }));
      }, 4000);
    };
  }, [auth, offer, dispatch]);
};

export default useOfferCreation;
