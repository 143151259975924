import { yupResolver } from '@hookform/resolvers/yup';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import ls from 'localstorage-slim';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { fetchCompanyDetails } from '../../../../api/instant-lease-api';
import Layout from '../../../../layout';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { EDirection } from '../../../../redux/sequence/enums';
import { setCurrentView } from '../../../../redux/sequence/sequence-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import { APIPaths, CrefoCompany } from '../../../../types/instant-lease-api';
import { getCompanyType, getText } from '../../../../utils/getter';
import { hasSpecialOrderNumber } from '../../../../utils/helper';
import { errorLogging } from '../../../../utils/logging';
import FormLayout from '../../../common/form-layout/form-layout';
import InputField from '../../../common/input-field/input-field';
import ButtonGroup from '../../molecules/button-group/button-group';

interface CompanySearchProps {
  createAction: ActionCreatorWithPayload<
    CrefoCompany[],
    'leaseApplication/setCompanySearchResult'
  >;
}

const CompanyInfo: React.FC<CompanySearchProps> = ({ createAction }) => {
  // Store
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Access Token
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // State company list result from api
  const [fetching, setFecthing] = useState<boolean>(false);

  // Init dispatch hook (used in nav)
  const dispatch = useAppDispatch();

  // Init history hook (used in nav)
  const navigate = useNavigate();

  // get company name and zip saved locally
  const companyNameLocal = ls.get('companyName') as any;

  const companyZipLocal = ls.get('companyZip') as any;

  // has special field
  const { hasSpecialField, fieldName, tooltip } =
    hasSpecialOrderNumber(activeApplication);

  // Validation schema for the form
  const validationSchema = useMemo(
    () =>
      yup.object({
        companyName: yup
          .string()
          .min(3, 'mindestens 3 Zeichen')
          .required('Pflichtfeld'),
        companyZipCodeOrRegion: yup.string().required('Pflichtfeld'),
        oc_number: hasSpecialField
          ? yup.string().required('Pflichtfeld')
          : yup.string().optional(),
      }),
    [hasSpecialField],
  );

  // Init react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    companyName: string;
    companyZipCodeOrRegion: string;
    oc_number?: string | undefined;
  }>({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      companyName: companyNameLocal || '',
      companyZipCodeOrRegion: companyZipLocal || '',
      oc_number: activeApplication?.offer?.partner_order_id,
    },
  });

  // On form submit
  const onSubmit = handleSubmit(
    ({ companyName, companyZipCodeOrRegion, oc_number }) => {
      setFecthing(true);
      ls.set('companyName', companyName);
      ls.set('companyZip', companyZipCodeOrRegion || '');
      fetchCompanyDetails(
        accessToken,
        companyName,
        companyZipCodeOrRegion || '',
      )
        .then((response) => {
          setFecthing(false);
          dispatch(createAction(response));
          if (hasSpecialField) {
            dispatch(
              updateCustomerData({
                accessToken,
                leaseApplicationId: activeApplication?.uuid || '',
                inputValue: {
                  version: 'V1_BETA_1',
                  order_contract_number: oc_number,
                },
                path: APIPaths.UPDATE_ORDER_NUMBER,
              }),
            );
          }
          dispatch(
            setCurrentView({
              sequence: 0,
              step: 1,
              completedTillStep: 1,
              direction: EDirection.FORWARDS,
              branches: 1,
              isStatusOverviewOpen: false,
              error: null,
            }),
          );
          navigate(
            `/lease-application/${activeApplication?.uuid}/company-profile/company-search-result`,
          );
        })
        .catch((e) => {
          console.log(e);
          errorLogging(e);
          dispatch(
            setCurrentView({
              sequence: 0,
              step: 1,
              completedTillStep: 1,
              direction: EDirection.FORWARDS,
              branches: 1,
              isStatusOverviewOpen: false,
              error: null,
            }),
          );
          navigate(
            `/lease-application/${activeApplication?.uuid}/company-profile/company-search-result`,
          );
        });
    },
  );

  // subtitle depending on the company type
  const getSubtitle = () => {
    const companyType = getCompanyType(activeApplication);
    if (companyType === 'kaufmann' || companyType === 'private') {
      return `${getText(
        'company_search_subtitle',
      )} Der Unternehmensname muss den Inhabernamen enthalten`;
    }
    if (companyType === 'partnership') {
      return `${getText(
        'company_search_subtitle',
      )} Der Unternehmensname muss die Namen der Gesellschafter enthalten`;
    }
    return `${getText('company_search_subtitle')}`;
  };

  // Use main layout
  return (
    <Layout
      stepStatus
      heading='Angaben zum Unternehmen'
      subHeading={getSubtitle()}
    >
      <FormLayout sectionTitle='Unternehmensdaten'>
        <form onSubmit={onSubmit}>
          <div>
            <InputField
              {...register('companyName')}
              label='Name des Unternehmens'
              error={errors.companyName?.message}
            />
            <InputField
              {...register('companyZipCodeOrRegion')}
              label='Postleitzahl/Ort'
              placeholder='22529 Hamburg'
              error={errors.companyZipCodeOrRegion?.message}
            />
          </div>
          {hasSpecialField && (
            <InputField
              label={fieldName}
              {...register('oc_number')}
              tooltip={tooltip}
              tooltipPosition='inline-block'
              error={errors.oc_number?.message}
            />
          )}
          <ButtonGroup
            className='back-next'
            type='back-next'
            buttonOneProps={{
              type: 'submit',
              loading: fetching,
              name: 'form-submit',
              dataTestId: 'next',
              children: 'Suchen',
            }}
          />
        </form>
      </FormLayout>
    </Layout>
  );
};

export default CompanyInfo;
