import { SequenceConfig } from '../redux/sequence/types';
import store from '../redux/store';
import { routes } from '../routes';
import { finalPageText, getPartnerText, getText } from '../utils/getter';
import { hasPostident } from '../utils/helper';
import sequenceConditions from './sequence-conditions';

const CompanySequence = (): SequenceConfig[] => {
  const activeLeaseApplication = store.getState().leaseApp?.activeApplication;
  return sequenceConditions([
    {
      key: 'company',
      name: getText('phase_title_1'),
      title: getPartnerText(`welcome_headline`),
      subTitle: getText('phase1start_subtitle'),
      steps: [
        {
          key: 'companyInfo',
          name: 'Informationen über die Firma',
          route: routes.leaseApplication.companyProfile.companyInfo,
        },
        {
          key: 'companySearchResult',
          name: 'Unternehmen suchen',
          route: routes.leaseApplication.companyProfile.companySearchResult,
        },
        {
          key: 'companyAddress',
          name: 'Adresse des Unternehmens',
          route: routes.leaseApplication.companyProfile.companyAddress,
        },
        {
          key: 'companyContacts',
          name: 'Kontakte zum Unternehmen',
          route: routes.leaseApplication.companyProfile.companyContacts,
        },
        {
          key: 'companyData',
          name: 'Daten zum Unternehmen',
          route: routes.leaseApplication.companyProfile.companyData,
        },
        {
          key: 'confirmCompany',
          name: 'Zusammenfassung',
          route: routes.leaseApplication.companyProfile.confirm,
        },
      ],
    },
    {
      key: 'kyc',
      name: 'Unternehmensvertreter',
      title: 'Unternehmensvertreter und Nachweise',
      subTitle: getText('phase2start_subtitle'),
      steps: [
        {
          key: 'beneficialOwners',
          name: 'Wirtschaftlich Berechtigte',
          route: routes.leaseApplication.kyc.beneficialOwners,
        },
        {
          key: 'authorizedRepresentatives',
          name: 'Vertretungsberechtigte',
          route: routes.leaseApplication.kyc.authorizedRepresentatives,
        },
        {
          key: 'ownerData',
          name: 'Unterzeichnerdaten',
          route: routes.leaseApplication.kyc.ownerData,
        },
        {
          key: 'transparenzregister',
          name: 'Transparenzregister',
          route: routes.leaseApplication.kyc.transparencyRegister,
        },
        {
          key: 'documentUpload',
          name: 'Dokument hochladen',
          route: routes.leaseApplication.kyc.documentUpload,
        },
        {
          key: 'confirmKyc',
          name: 'Zusammenfassung',
          route: routes.leaseApplication.kyc.confirm,
        },
      ],
    },
    {
      key: 'creditCheck',
      name: 'Bonitätsprüfung',
      title: getText('credit_check_overview_title'),
      subTitle: getPartnerText('phase3_subtitle'),
      steps: [
        {
          key: 'creditCheckStart',
          name: 'Kreditprüfung starten',
          route: routes.leaseApplication.creditCheck.creditCheckStart,
        },

        {
          key: 'creditCheckStatus',
          name: 'Kreditprüfung',
          route: routes.leaseApplication.creditCheck.creditCheckStatus,
        },
      ],
    },
    {
      key: 'identityCheck',
      name: 'Unterschrift',
      title: getText('idnow_overview_title'),
      subTitle: getPartnerText('idnow_subtitle'),
      steps: hasPostident(activeLeaseApplication),
    },
    {
      key: 'success',
      name: 'Success Message',
      title: finalPageText(activeLeaseApplication),
      subTitle: '',
      steps: [],
    },
    {
      key: 'something wrong',
      name: 'Wrong Message',
      title: 'Entschuldigung, da ist etwas schief gelaufen',
      subTitle: '',
      steps: [],
    },
  ]);
};

export default CompanySequence;
