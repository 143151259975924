import { ReactElement } from 'react';
import {
  setBeneficialOwners,
  setCompanySearchResult,
} from '../redux/leaseApp/lease-app-slice';
import { routes } from '../routes';
import { Individual } from '../types/instant-lease-api';
import { getPartnerText, getText } from '../utils/getter';
import ConfirmCompanyData from '../view/b2b/components/confirm-company-data/confirm-company-data';
import CmTrRegistry from '../view/b2b/pages/cm-tr-registry/cm-tr-registry';
import CompanyAddress from '../view/b2b/pages/company-address/company-address';
import CompanyContacts from '../view/b2b/pages/company-contacts/company-contacts';
import CompanyData from '../view/b2b/pages/company-data/company-data';
import CompanyInfo from '../view/b2b/pages/company-info/company-info';
import CompanySearchResult from '../view/b2b/pages/company-search-result/company-search-result';
import ConfirmKYC from '../view/b2b/pages/confirm-kyc/confirm-kyc';
import Contract from '../view/b2b/pages/contract/contract';
import CreditCheckResult from '../view/b2b/pages/credit-check-result/credit-check-result';
import FlexFinal from '../view/b2b/pages/flex-final/flex-final';
import HasBeneficialOwners from '../view/b2b/pages/has-beneficial-owners/has-benificial-owners';
import IdNow from '../view/b2b/pages/id-now/id-now';
import SignerData from '../view/b2b/pages/signer-data/signer-data';
import BeneficialOwners from '../view/b2b/templates/beneficial-owners/beneficial-owners';
import Creditcheck from '../view/b2b/templates/credit-check/credit-check';
import RequiredDocuments from '../view/b2b/templates/required-documents/required-documents';
import SignersListAGB from '../view/b2b/templates/signers-list-agb/signers-list-agb';
import SignersList from '../view/b2b/templates/signers-list/signers-list';
import PostidentIntro from '../view/common/postident-intro/postident-intro';
import Postident from '../view/common/postident/postident';

type RouteDef = {
  key: string;
  route: (leaseAppId?: string) => string;
  element: ReactElement | null;
};

const IndividualRoutes: RouteDef[] = [
  {
    key: 'companyInfo',
    route: routes.leaseApplication.companyProfile.companyInfo,
    element: <CompanyInfo createAction={setCompanySearchResult} />,
  },
  {
    key: 'companySearchResult',
    route: routes.leaseApplication.companyProfile.companySearchResult,
    element: <CompanySearchResult />,
  },
  {
    key: 'companyAddress',
    route: routes.leaseApplication.companyProfile.companyAddress,
    element: (
      <CompanyAddress
        getLegalform={(leaseApp) => leaseApp?.customer?.individual?.legal_form}
        getCustomer={(activeApplication) =>
          activeApplication?.customer?.individual
        }
      />
    ),
  },
  {
    key: 'companyContacts',
    route: routes.leaseApplication.companyProfile.companyContacts,
    element: (
      <CompanyContacts
        getCustomer={(activeApplication) =>
          activeApplication?.customer?.individual
        }
      />
    ),
  },
  {
    key: 'companyData',
    route: routes.leaseApplication.companyProfile.companyData,
    element: (
      <CompanyData
        getCustomer={(activeApplication) =>
          activeApplication?.customer?.individual
        }
      />
    ),
  },
  {
    key: 'confirmCompany',
    route: routes.leaseApplication.companyProfile.confirm,
    element: (
      <ConfirmCompanyData
        title='Zusammenfassung deiner Daten'
        subTitle='Bitte überprüfe deine Eingaben. Solltest du etwas anpassen wollen, klicke einfach auf „Ändern“.'
      />
    ),
  },

  {
    key: 'hasBeneficialOwners',
    route: routes.leaseApplication.kyc.hasBeneficialOwners,
    element: (
      <HasBeneficialOwners
        title={getText('beneficial_owners_initial_question')}
        extraInfo={getText('beneficial_owners_popup_content')}
      />
    ),
  },
  {
    key: 'beneficialOwners',
    route: routes.leaseApplication.kyc.beneficialOwners,
    element: (
      <BeneficialOwners
        title={getText('beneficial_owners_collection_headline')}
        emptyListTitle={getText('beneficial_owners_collection_headline')}
        getLegalform={(leaseApp) => leaseApp?.customer?.individual?.legal_form}
        subheadline='Bitte füge alle wirtschaftlich berechtigten Personen hinzu.'
        BOModalTitle={getText('beneficial_owners_collection_popup_subtitle')}
        createAction={setBeneficialOwners}
        getInitialValue={(activeApplication) =>
          activeApplication?.customer?.individual?.beneficial_owners
        }
      />
    ),
  },

  {
    key: 'ownerData',
    route: routes.leaseApplication.kyc.ownerData,
    element: (
      <SignerData
        type='individual'
        getSigner={(activeApplication) =>
          activeApplication?.customer?.individual?.signers
        }
      />
    ),
  },

  {
    key: 'transparencyRegister',
    route: routes.leaseApplication.kyc.transparencyRegister,
    element: (
      <CmTrRegistry
        getInitialValue={(activeApplication) =>
          activeApplication?.customer?.individual as Individual
        }
      />
    ),
  },
  {
    key: 'documentUpload',
    route: routes.leaseApplication.kyc.documentUpload,
    element: <RequiredDocuments />,
  },
  {
    key: 'confirmKyc',
    route: routes.leaseApplication.kyc.confirm,
    element: <ConfirmKYC />,
  },
  {
    key: 'creditCheckStart',
    route: routes.leaseApplication.creditCheck.creditCheckStart,
    element: (
      <Creditcheck
        type='individual'
        extraInfo={getPartnerText('schufa_text')}
      />
    ),
  },
  {
    key: 'creditCheckResult',
    route: routes.leaseApplication.creditCheck.creditCheckStatus,
    element: <CreditCheckResult />,
  },
  {
    key: 'flexFinal',
    route: routes.leaseApplication.flex.flexFinal,
    element: <FlexFinal />,
  },
  {
    key: 'reviewContract',
    route: routes.leaseApplication.identityCheck.reviewContract,
    element: <Contract />,
  },
  {
    key: 'idNow',
    route: routes.leaseApplication.identityCheck.idNow,
    element: <IdNow />,
  },
  {
    key: 'postIdenetIntro',
    route: routes.leaseApplication.identityCheck.postidentStart,
    element: <PostidentIntro />,
  },
  {
    key: 'postIdenetCoupon',
    route: routes.leaseApplication.identityCheck.postidentCoupon,
    element: <Postident />,
  },
  {
    key: 'signersListAGB',
    route: routes.leaseApplication.identityCheck.signersListAGB,
    element: <SignersListAGB />,
  },
  {
    key: 'signersList',
    route: routes.leaseApplication.identityCheck.signersList,
    element: <SignersList />,
  },
];

export default IndividualRoutes;
