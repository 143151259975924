import { ReactElement } from 'react';
import { routes } from '../routes';
import { getPartnerText } from '../utils/getter';
import Contract from '../view/b2b/pages/contract/contract';
import CreditCheckResult from '../view/b2b/pages/credit-check-result/credit-check-result';
import FlexFinal from '../view/b2b/pages/flex-final/flex-final';
import IDNow from '../view/b2b/pages/id-now/id-now';
import Creditcheck from '../view/b2b/templates/credit-check/credit-check';
import SignersListAGB from '../view/b2b/templates/signers-list-agb/signers-list-agb';
import SignersList from '../view/b2b/templates/signers-list/signers-list';
import BankB2c from '../view/b2c/pages/bank-b2c/bank-b2c';
import ContactB2c from '../view/b2c/pages/contact-b2c/contact-b2c';
import DataSummary from '../view/b2c/pages/data-summary/data-summary';
import HasCoApplicant from '../view/b2c/pages/has-co-applicant/has-co-applicant';
import IncomeAndExpenses from '../view/b2c/pages/income-and-expenses/income-and-expenses';
import PersonalInformation from '../view/b2c/pages/personal-information/personal-information';
import UploadDocuments from '../view/b2c/pages/upload-documents/upload-documents';
import WorkFamilyHousing from '../view/b2c/pages/work-family-housing/work-family-housing';
import IdnowLoggedIn from '../view/common/idnow-response/idnow-loggedin/idnow-loggedin';
import PostidentIntro from '../view/common/postident-intro/postident-intro';
import Postident from '../view/common/postident/postident';

type RouteDef = {
  key: string;
  route: (leaseAppId?: string) => string;
  element: ReactElement | null;
};

const PrivateRoutes: RouteDef[] = [
  {
    key: 'personalInformation',
    route: routes.leaseApplication.self_disclosure.personalInformation,
    element: (
      <PersonalInformation
        getPrivateCustomer={(activeApplication) =>
          activeApplication?.customer?.private
        }
      />
    ),
  },
  {
    key: 'workFamilyHousing',
    route: routes.leaseApplication.self_disclosure.workFamilyHousing,
    element: (
      <WorkFamilyHousing
        getPrivateCustomer={(activeApplication) =>
          activeApplication?.customer?.private
        }
      />
    ),
  },
  {
    key: 'incomeAndExpenses',
    route: routes.leaseApplication.self_disclosure.incomeAndExpenses,
    element: (
      <IncomeAndExpenses
        getPrivateCustomer={(activeApplication) =>
          activeApplication?.customer?.private
        }
      />
    ),
  },
  {
    key: 'contact',
    route: routes.leaseApplication.self_disclosure.contact,
    element: <ContactB2c />,
  },

  {
    key: 'bank',
    route: routes.leaseApplication.self_disclosure.bank,
    element: <BankB2c />,
  },
  {
    key: 'uploadDocuments',
    route: routes.leaseApplication.self_disclosure.uploadDocuments,
    element: <UploadDocuments />,
  },
  {
    key: 'hasPartner',
    route: routes.leaseApplication.self_disclosure.hasCoApplicant,
    element: <HasCoApplicant />,
  },
  {
    key: 'dataSummary',
    route: routes.leaseApplication.self_disclosure.dataSummary,
    element: <DataSummary />,
  },
  {
    key: 'creditCheckB2CStart',
    route: routes.leaseApplication.creditCheckB2C.creditCheckB2CStart,
    element: (
      <Creditcheck
        type='individual'
        extraInfo={getPartnerText('schufa_text')}
      />
    ),
  },
  {
    key: 'creditCheckB2CStatus',
    route: routes.leaseApplication.creditCheckB2C.creditCheckB2CStatus,
    element: <CreditCheckResult />,
  },
  {
    key: 'flexFinal',
    route: routes.leaseApplication.flex.flexFinal,
    element: <FlexFinal />,
  },
  {
    key: 'reviewContractB2C',
    route: routes.leaseApplication.identityCheckB2C.reviewContractB2C,
    element: <Contract />,
  },
  {
    key: 'idNowB2C',
    route: routes.leaseApplication.identityCheckB2C.idNowB2C,
    element: <IDNow />,
  },
  {
    key: 'idNowResponse',
    route: routes.leaseApplication.identityCheckB2C.idNowResponse,
    element: <IdnowLoggedIn />,
  },
  {
    key: 'postIdenetIntro',
    route: routes.leaseApplication.identityCheck.postidentStart,
    element: <PostidentIntro />,
  },
  {
    key: 'postIdenetCoupon',
    route: routes.leaseApplication.identityCheck.postidentCoupon,
    element: <Postident />,
  },
  {
    key: 'signersListAGB',
    route: routes.leaseApplication.identityCheck.signersListAGB,
    element: <SignersListAGB />,
  },
  {
    key: 'signersList',
    route: routes.leaseApplication.identityCheck.signersList,
    element: <SignersList />,
  },
];

export default PrivateRoutes;
