import _ from 'lodash';
import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDocument } from '../../../../api/instant-lease-api';
import Layout from '../../../../layout';
import { setNotification } from '../../../../redux/cache-slice';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  Document,
  Partner,
  Type,
} from '../../../../types/instant-lease-api';
import {
  getAppTypeLowerCase,
  getPartnerText,
  getText,
} from '../../../../utils/getter';
import Navigation from '../../../../utils/navigation';
import FormLayout from '../../../common/form-layout/form-layout';
import Checkbox from '../../components/checkbox/checkbox';
import { ReactComponent as OpenNew } from '../../components/icons/open-new.svg';
import { ReactComponent as FileIcon } from '../../components/icons/pdf-icon.svg';
import ButtonGroup from '../../molecules/button-group/button-group';
import './contract.css';

const Contract: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Loading State
  const [loading, setLoading] = useState<boolean>(false);

  // Leaseing Application Type
  const type = getAppTypeLowerCase(activeApplication?.type);

  // Check if document exists
  const isDocumentExist = (documentType: string): Document | undefined =>
    activeApplication?.registered_documents?.find(
      (doc) => doc.document_type === documentType,
    );

  // Download the requested from API
  const downloadFile = (document: Document | undefined) => {
    if (document) {
      const { mime_type, token, file_name } = document;
      getDocument(accessToken, activeApplication?.uuid || '', token)
        .then((response) => {
          const blob = window.URL.createObjectURL(
            new Blob([response], { type: mime_type }),
          );
          const link = window.document.createElement('a');
          link.href = blob;
          // link.download = file_name;
          link.setAttribute('download', file_name);
          link.click();
          window.open(blob, '_blank');
        })
        .catch(() => {
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
        });
    }
  };

  // Disclaimer state
  const AGBAgreementsValue =
    activeApplication?.customer?.agb_agreements?.confirmed?.answer;

  const ALDAgreementsValue =
    activeApplication?.customer?.ald_agreements?.terms_and_conditions?.answer;

  // Insurance agreements value
  const value =
    activeApplication?.customer?.insurance_agreements?.confirmed?.answer;

  // some partner has extra checkbox below the value control
  const [checkboxOneValue, setCheckboxOneValue] = useState<boolean>(
    ALDAgreementsValue || false,
  );

  const [checkboxTwoValue, setCheckboxTwoValue] = useState<boolean>(
    ALDAgreementsValue || false,
  );

  const [checkboxThreeValue, setCheckboxThreeValue] = useState<boolean>(
    ALDAgreementsValue || false,
  );

  const [checkboxFourValue, setCheckboxFourValue] = useState<boolean>(
    ALDAgreementsValue || false,
  );

  // Get AGB Link
  const getAGBLink = (): string => {
    const partner = activeApplication?.partner.toLowerCase();
    const type = activeApplication?.type === Type.B2C;
    if (type) {
      switch (partner) {
        case Partner.LYNKCO.toLowerCase():
          return 'https://cdn.ald-online.de/cms/assets/LCL_AGB_Verbraucher_24_07_Online_B2_C_de56044347.pdf';
        case Partner.SMART.toLowerCase():
          return 'https://cdn.ald-online.de/cms/assets/SML_AGB_Verbraucher_24_07_Online_B2_C_0d946518d6.pdf';
        default:
          return 'https://cdn.ald-online.de/cms/assets/ALD_AGB_Verbraucher_24_07_Online_B2_C_ALD_Online_5f836ac259.pdf';
      }
    }
    return 'https://www.ald-online.de/pages/allgemeine-geschaeftsbedingungen-gewerbe';
  };

  // Dispatch hook
  const dispatch = useAppDispatch();

  // history hook
  const navigate = useNavigate();

  // Navigation
  const { nextRoute } = new Navigation();

  // Update Agreements on check
  const updateInsuranceAgreements = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          confirmed: event.target.checked,
        },
        path: APIPaths.INSURANCE_AGREEMENTS,
      }),
    )
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  // Update Agreements on check
  const updateAGBAgreements = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          confirmed: event.target.checked,
        },
        path: APIPaths.UPDATE_AGB_AGREEMENTS,
      }),
    )
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const hasALDAgreements = () => {
    const membersHasALDAgreements = [
      'mb member',
      'smart_member',
      'member_mb_conf1',
      'member_mb conf2',
      'member_mb_conf3',
      'member_mb conf4',
      'member_mb_conf5',
      'member_mb conf6',
      'member_mb_conf7',
      'member_mb_conf8',
      'member_smart_conf1',
      'member_smart_conf2',
      'member_smart conf3',
      'member_smart_conf4',
      'member_smart_conf5',
      'member_smart_conf6',
      'member smart conf7',
      'member_smart_conf8',
      'corporate_benefits_smart',
      'smart_enpal',
    ];
    return membersHasALDAgreements.includes(
      activeApplication?.loyalty_card?.loyalty_card_type?.toLowerCase() || '',
    );
  };

  const isDisabled = () => {
    if (hasCarInsurance && !value) {
      return true;
    }

    if (!AGBAgreementsValue) {
      return true;
    }

    if (
      [
        checkboxOneValue,
        checkboxTwoValue,
        checkboxThreeValue,
        checkboxFourValue,
      ].includes(false) &&
      hasALDAgreements()
    ) {
      return true;
    }

    return false;
  };

  // if it has car insurance
  const hasCarInsurance = !_.isEmpty(
    activeApplication?.offer?.leasing_options?.insurance,
  );

  const submit = () => {
    setLoading(true);
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          terms_and_conditions: true,
        },
        path: APIPaths.ALD_AGREEMENTS,
      }),
    )
      .then(() => {
        setLoading(false);
        navigate(nextRoute());
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Layout
      heading='Bitte prüfe deinen Leasingvertrag'
      stepStatus
      className='contract'
    >
      <FormLayout sectionTitle='Unterzeichner-Dokumente'>
        <div className='upload-container'>
          <div
            className='upload-box'
            role='presentation'
            onClick={() =>
              downloadFile(isDocumentExist('UNSIGNED_LEASING_PROPOSAL'))
            }
          >
            <div className='upload-box-details-contract'>
              <div className='upload-box-details-upload-icon-box'>
                <FileIcon />
              </div>

              <div className='file-name'>
                <h2 className='upload-box-details-text content-bold'>
                  Dein Leasingvertrag
                </h2>
              </div>
            </div>
            <div className='upload-box-actions green'>
              <OpenNew />
            </div>
          </div>
        </div>
        {hasCarInsurance && (
          <div className='upload-container'>
            <a
              className='upload-box'
              href={getPartnerText(`insurance_link_${type}`)}
              target='_blank'
              rel='noopener noreferrer'
            >
              <div className='upload-box-details-contract'>
                <div className='upload-box-details-upload-icon-box'>
                  <FileIcon />
                </div>

                <div className='file-name'>
                  <h2 className='upload-box-details-text content-bold'>
                    Versicherungsbedingungen
                  </h2>
                </div>
              </div>
              <div className='upload-box-actions green'>
                <OpenNew />
              </div>
            </a>
          </div>
        )}
        <div className='checkbox-insurance-box'>
          {hasCarInsurance && (
            <Checkbox
              type='checkbox'
              square
              checkboxPosition='top'
              name='schufa_data'
              style={{ alignSelf: 'baseline' }}
              checked={value}
              onChange={updateInsuranceAgreements}
            >
              <p className='input-text checkbox-content'>
                Ich habe die Versicherungsbedingungen heruntergeladen und
                gelesen.
              </p>
            </Checkbox>
          )}
          <Checkbox
            name='disclaimer'
            checked={AGBAgreementsValue}
            type='checkbox'
            square
            checkboxPosition='top'
            onChange={updateAGBAgreements}
          >
            <p className='input-text checkbox-content'>
              Ich akzeptiere die
              <a
                target='_blank'
                rel='noopener noreferrer'
                role='button'
                href={getAGBLink()}
              >
                {' '}
                Allgemeinen Geschäftsbedingungen (AGB) der ALD AutoLeasing D
                GmbH.
              </a>
            </p>
          </Checkbox>
          {hasALDAgreements() && (
            <>
              <Checkbox
                name='disclaimer'
                checked={checkboxOneValue}
                type='checkbox'
                square
                checkboxPosition='top'
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCheckboxOneValue(e.target.checked)
                }
                style={{ alignSelf: 'baseline' }}
              >
                <div className='input-text'>
                  Ich akzeptiere die{' '}
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    role='button'
                    href='https://de.smart.com/de/service/terms-of-service/'
                  >
                    Allgemeinen Geschäftsbedingungen des Herstellers für
                    digitale Dienste.
                  </a>
                </div>
              </Checkbox>
              <Checkbox
                name='disclaimer'
                checked={checkboxTwoValue}
                type='checkbox'
                square
                checkboxPosition='top'
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCheckboxTwoValue(e.target.checked)
                }
                style={{ alignSelf: 'baseline' }}
              >
                <div className='input-text'>
                  Ich akzeptiere die{' '}
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    role='button'
                    href='https://de.smart.com/de/service/terms-conditions-isp/'
                  >
                    Bedingungen für das integrierte Servicepaket.
                  </a>
                </div>
              </Checkbox>
              <Checkbox
                name='disclaimer'
                checked={checkboxThreeValue}
                type='checkbox'
                square
                checkboxPosition='top'
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCheckboxThreeValue(e.target.checked)
                }
                style={{ alignSelf: 'baseline' }}
              >
                <div className='input-text'>
                  Ich akzeptiere die{' '}
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    role='button'
                    href='https://de.smart.com/de/service/warranty-terms/'
                  >
                    Garantiebedingungen.
                  </a>
                </div>
              </Checkbox>{' '}
              <Checkbox
                name='disclaimer'
                checked={checkboxFourValue}
                type='checkbox'
                square
                checkboxPosition='top'
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCheckboxFourValue(e.target.checked)
                }
                style={{ alignSelf: 'baseline' }}
              >
                <div className='input-text'>
                  Ich akzeptiere die{' '}
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    role='button'
                    href='https://de.smart.com/de/service/terms-of-use/'
                  >
                    Nutzungsbedingungen für smart ID.
                  </a>
                </div>
              </Checkbox>
            </>
          )}
        </div>
      </FormLayout>
      <ButtonGroup
        type='back-next'
        className='back-next medium'
        buttonOneProps={{
          type: 'submit',
          disabled: isDisabled(),
          loading,
          onClick: () => submit(),
          dataTestId: 'next',
          children: 'weiter',
        }}
      />
    </Layout>
  );
};

export default Contract;
