import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import countries from '../../../../localization/countries.json';
import { RootState } from '../../../../redux/types';
import { LegalForm, Person } from '../../../../types/instant-lease-api';
import { getText } from '../../../../utils/getter';
import FormLayout from '../../../common/form-layout/form-layout';
import InputDate from '../../../common/input-field/input-date';
import InputField from '../../../common/input-field/input-field';
import SelectBox from '../../../common/select-box/select-box';
import Button from '../../components/button/button';
import RadioContainer from '../../components/radio-container/radio-container';
import Radio from '../../components/radio/radio';
import UploadBox from '../../components/upload-box/upload-box';

export interface PersonalDataSubmittedValues {
  uuid?: string;
  person_uuid?: string;
  first_name: string;
  last_name: string;
  birth_place: string;
  date_of_birth: string;
  citizenship: string;
  authorityChoice: string;
  modified?: boolean;
  letter_of_attorney_file_key?: string;
  letter_of_attorney_signer_id_file_key?: string;
}

interface PersonalDataFormValues {
  authorityChoice?: string | null | undefined;
  first_name: string;
  last_name: string;
  birth_place: string;
  date_of_birth: string;
  citizenship: string;
}

interface PersonalDataProps {
  onSubmit: (signer: PersonalDataSubmittedValues) => void;
  signer?: Person;
  showAuthorities?: boolean;
  authority?: string;
  setError?: (t: boolean) => void;
  loading?: boolean;
}

const PersonalData: React.FC<PersonalDataProps> = ({
  onSubmit,
  signer,
  showAuthorities,
  authority,
  setError,
  loading,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Choice value to show files
  const [choiceValue, setChoiceValue] = useState<string>();

  // Customer value
  const customer =
    activeApplication?.customer?.individual ||
    activeApplication?.customer?.company;

  const authOwnerLabel =
    customer?.legal_form === LegalForm.GESELLSCHAFT_BUERGERLICHEN_RECHTS
      ? 'Vertretungsberechtigt gemäß Gesellschaftsvertrag'
      : 'vertretungsberechtigt';

  const onlyOwner = (): boolean => {
    if (
      customer?.legal_form === LegalForm.GEWERBEBETRIEB ||
      customer?.legal_form === LegalForm.FREIE_BERUFE ||
      customer?.legal_form === LegalForm.EINGETRAGENER_KAUFMANN
    ) {
      return true;
    }
    return false;
  };
  const authorities = [
    {
      value: 'PROFESSIONAL_AUTHORITY_OWNER',
      label: `${authOwnerLabel || 'vertretungsberechtigt'}`,
      disable: false,
    },
  ];
  if (!onlyOwner()) {
    authorities.push({
      value: 'PROFESSIONAL_AUTHORITY_ATTORNEY',
      label: 'bevollmächtigt',
      disable: false,
    });
  }

  // Form validation
  const validationSchema = yup.object({
    first_name: yup.string().required('Pflichtfeld').min(2),
    last_name: yup.string().required('Pflichtfeld').min(2),
    birth_place: yup.string().required('Pflichtfeld'),
    authorityChoice: showAuthorities
      ? yup.string().required('Pflichtfeld')
      : yup.string().nullable(),
    date_of_birth: yup
      .string()
      .matches(
        /^(((0?[1-9]|[12]\d|3[01])\.(0[13578]|[13578]|1[02])\.((1[6-9]|[2-9]\d)\d{2}))|((0?[1-9]|[12]\d|30)\.(0[13456789]|[13456789]|1[012])\.((1[6-9]|[2-9]\d)\d{2}))|((0?[1-9]|1\d|2[0-8])\.0?2\.((1[6-9]|[2-9]\d)\d{2}))|(29\.0?2\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/,
        'TT.MM.JJJJ',
      )
      .required('Pflichtfeld'),
    citizenship: yup.string().required('Pflichtfeld'),
  });

  // Form Hook
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    formState: { isDirty },
    trigger,
  } = useForm<PersonalDataFormValues>({
    mode: 'onTouched',
    defaultValues: {},
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  });

  // validatedDocument
  const validatedDocument = () => {
    const uploadedDocuments = activeApplication?.registered_documents;
    const LOA = uploadedDocuments?.find(
      (document) => document.document_type === 'LETTER_OF_ATTORNEY',
    );
    const LOAID = uploadedDocuments?.find(
      (document) => document.document_type === 'LETTER_OF_ATTORNEY_SIGNER_ID',
    );

    if (choiceValue === 'PROFESSIONAL_AUTHORITY_OWNER') {
      return true;
    }

    if (choiceValue === 'PROFESSIONAL_AUTHORITY_ATTORNEY' && LOA && LOAID) {
      return true;
    }

    if (!showAuthorities) {
      return true;
    }

    return false;
  };

  // hook controlling authority
  useEffect(() => {
    if (!_.isEmpty(signer) && signer?.first_name) {
      setValue('first_name', signer?.first_name, {
        shouldValidate: signer?.first_name === '',
      });
      setValue('last_name', signer?.last_name || '', {
        shouldValidate: signer?.last_name === '',
      });
      setValue('birth_place', signer?.birth_place || '', {
        shouldValidate: signer?.birth_place === '',
      });
      setValue('authorityChoice', authority, {
        shouldValidate: authority === '',
      });
      setChoiceValue(authority);
      setValue('date_of_birth', signer?.date_of_birth || '', {
        shouldValidate: signer?.date_of_birth === '',
      });
      setValue('citizenship', signer?.citizenship || '', {
        shouldValidate: signer?.citizenship === '',
      });
      trigger();
    }
  }, [authority, signer, setValue, trigger, isDirty]);

  // hook controlling error
  useEffect(() => {
    if (setError && isDirty && !_.isEmpty(errors)) {
      setError(false);
    }
  }, [errors, isDirty, setError]);

  // Form submit handler
  const formSubmitHandler = (values: PersonalDataFormValues) => {
    if (
      choiceValue === 'PROFESSIONAL_AUTHORITY_ATTORNEY' &&
      !validatedDocument()
    ) {
      return;
    }

    if (signer?.uuid) {
      onSubmit({
        uuid: signer?.uuid,
        person_uuid: signer?.uuid,
        first_name: values.first_name,
        last_name: values.last_name,
        birth_place: values.birth_place,
        authorityChoice: values.authorityChoice || '',
        date_of_birth: values.date_of_birth,
        citizenship: values.citizenship,
        modified: true,
      });
    } else {
      onSubmit({
        uuid: uuidv4(),
        person_uuid: uuidv4(),
        first_name: values.first_name,
        last_name: values.last_name,
        birth_place: values.birth_place,
        date_of_birth: values.date_of_birth,
        citizenship: values.citizenship,
        authorityChoice: values.authorityChoice || '',
        modified: true,
      });
    }
  };

  return (
    <FormLayout sectionTitle='Personendaten'>
      <form>
        <InputField
          {...register('first_name')}
          label='Vorname(n)'
          withErrorMessage
          error={errors.first_name?.message}
        />
        <InputField
          {...register('last_name')}
          label='Nachname(n)'
          withErrorMessage
          error={errors.last_name?.message}
        />
        <InputDate
          setValue={setValue}
          trigger={trigger}
          label='Geburtsdatum'
          placeholder='TT.MM.JJJJ'
          {...register('date_of_birth')}
          error={errors?.date_of_birth?.message}
        />
        <InputField
          {...register('birth_place')}
          label='Geburtsort'
          withErrorMessage
          error={errors.birth_place?.message}
        />
        <SelectBox
          initialValue={watch('citizenship')}
          setValueCallBack={(value) =>
            setValue('citizenship', value, { shouldValidate: true })
          }
          {...register('citizenship')}
          label='Nationalität'
          options={countries}
          hasError={!!errors?.citizenship?.message}
          errorMessage={errors?.citizenship?.message}
          dataTestId='citizenship-select'
        />
        {showAuthorities && (
          <>
            <div className='choice-selection'>
              <RadioContainer subTitle='Ich bin :'>
                {authorities?.map(({ value, label, disable }, index) => (
                  <Radio
                    disabled={disable}
                    checked={value === choiceValue}
                    onChange={(e: any) => {
                      setValue('authorityChoice', value);
                      setChoiceValue(value);
                    }}
                    key={index}
                  >
                    <span className='content-normal'>{label} </span>
                  </Radio>
                ))}
              </RadioContainer>
              {errors.authorityChoice?.message && (
                <p className='error small-text left'>Pflichtfeld</p>
              )}
            </div>

            {choiceValue === 'PROFESSIONAL_AUTHORITY_ATTORNEY' && (
              <div>
                <UploadBox
                  className='top-24'
                  fileName={getText('your_lawyer')}
                  documentType='LETTER_OF_ATTORNEY'
                />
                <UploadBox
                  className='top-24'
                  fileName='Ausweis des Vollmachtgebers'
                  documentType='LETTER_OF_ATTORNEY_SIGNER_ID'
                />
              </div>
            )}
          </>
        )}
        <div className='add-additional-person'>
          <Button
            theme='secondary'
            type='button'
            className='save-button next button-text save-and-next-tab'
            role='presentation'
            loading={loading}
            onClick={handleSubmit(formSubmitHandler)}
          >
            nächster Schritt
          </Button>
        </div>
      </form>
    </FormLayout>
  );
};

export default PersonalData;
