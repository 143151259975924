import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EDevice } from '../../../../redux/sequence/enums';
import { RootState } from '../../../../redux/types';
import { ContactPurpose, Signer } from '../../../../types/instant-lease-api';
import { getCoApplicant, getText } from '../../../../utils/getter';
import { updateB2CCoSigner } from '../../../../utils/helper';
import Navigation from '../../../../utils/navigation';
import {
  formatBankDetails,
  formatGermanDateToUTC,
} from '../../../../utils/sanitize-data';
import { validateSignerData } from '../../../../utils/validatetor';
import TabContent from '../../../b2b/components/tab-content/tab-content';
import TabItem from '../../../b2b/components/tab-item/tab-item';
import BankTemplate, {
  BankTemplateData,
} from '../../templates/bank-template/bank-template';
import ContactTemplate, {
  ContactTemplateData,
} from '../../templates/contact-template/contact-template';
import DocumentTemplate, {
  DocumentDataSubmittedValues,
} from '../../templates/document-template/document-template';
import IncomeTemplate, {
  IncomeTemplateData,
} from '../../templates/income-template/income-template';
import PersonalTemplate, {
  PersonalData,
} from '../../templates/personal-template/personal-template';
import WorkTemplate, {
  WorkTemplateData,
} from '../../templates/work-template/work-template';

const CoApplicant: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Sequence Storage
  const { device } = useSelector((state: RootState) => state.sequence);

  // Get CoApplicant Data
  const coApplicant = getCoApplicant(
    activeApplication?.customer?.private?.signers,
  );

  // Histroy Hook
  const navigate = useNavigate();

  // Navigation
  const { nextRoute, previousRoute } = new Navigation();
  const goBack = () => navigate(previousRoute());

  // Active Tab Control
  const [activeTab, setActiveTab] = useState('personalData');

  // Personal Data Tab Control
  const [personalData, setPersonalData] = useState<boolean>(false);

  // Contact Data Tab Control
  const [contactData, setContactData] = useState<boolean>(false);

  // Work Data Tab Control
  const [workData, setWorkData] = useState<boolean>(false);

  // Income Data Tab Control
  const [incomeData, setIncomeData] = useState<boolean>(false);

  // Bank Data Tab Control
  const [bankData, setBankData] = useState<boolean>(false);

  // Documents Data Tab Control
  const [documentData, setDocumentData] = useState<boolean>(false);

  // Loading State
  const [loading, setLoading] = useState<boolean>(false);

  // Set Signer internally
  const [signerData, setSignerData] = useState<Signer | undefined>(coApplicant);

  const scrollToTop = () => {
    window.scrollTo({
      top: device === EDevice.MOBILE ? 570 : 525,
      left: 0,
      behavior: 'smooth',
    });
  };

  // Save Personal Data
  const submitPersonalData = async (modifiedPersonalData: PersonalData) => {
    setLoading(true);
    const updatedSigner = {
      ...signerData,
      person: {
        ...signerData?.person,
        uuid: modifiedPersonalData.uuid,
        gender: modifiedPersonalData?.gender,
        first_name: modifiedPersonalData?.first_name,
        last_name: modifiedPersonalData?.last_name,
        birth_place: modifiedPersonalData?.birth_place,
        citizenship: modifiedPersonalData?.citizenship,
        date_of_birth: modifiedPersonalData?.date_of_birth,
        contact_details: {
          ...signerData?.person?.contact_details,
          addresses: [
            {
              postal_code: modifiedPersonalData?.postal_code,
              street_line: modifiedPersonalData?.street_line,
              house_number: modifiedPersonalData?.house_number,
              locality: modifiedPersonalData.locality,
              additional_lines: [''],
            },
          ],
        },
      },
      type: modifiedPersonalData.type,
    };
    setSignerData(updatedSigner);
    updateB2CCoSigner(activeApplication, accessToken, updatedSigner).then(
      () => {
        setLoading(false);
        setSignerData(updatedSigner);
        setPersonalData(true);
        setActiveTab('contactData');
        handleSliderTabsClick(0);
        scrollToTop();
      },
    );
  };

  // Save contact Data
  const submitContactData = async (
    modifiedContactData: ContactTemplateData,
  ) => {
    setLoading(true);
    const updatedSigner = {
      ...signerData,
      person: {
        ...signerData?.person,
        contact_details: {
          ...signerData?.person?.contact_details,
          emails: [
            {
              value: modifiedContactData?.email,
              purpose: ContactPurpose.CONTACT,
            },
          ],
          phone_numbers: [
            {
              value: modifiedContactData?.phone_number,
              purpose: ContactPurpose.CONTACT,
              data_usage: {
                answer: modifiedContactData.terms,
              },
            },
          ],
        },
      },
    };

    setSignerData(updatedSigner);
    updateB2CCoSigner(activeApplication, accessToken, updatedSigner).then(
      () => {
        setLoading(false);
        setContactData(true);
        setActiveTab('workData');
        handleSliderTabsClick(120);
        scrollToTop();
      },
    );
  };

  // Save contact Data
  const submitWorkData = async (modifiedWorkData: WorkTemplateData) => {
    setLoading(true);
    const updatedSigner = {
      ...signerData,
      person: {
        ...signerData?.person,
      },
      living_details: {
        ...signerData?.living_details,
        employment_type: modifiedWorkData.employment_type,
        employment_since: formatGermanDateToUTC(
          modifiedWorkData.employment_since as string,
        ),
        marital_status: modifiedWorkData.marital_status,
        number_of_children: modifiedWorkData.number_of_children,
        residence_type: modifiedWorkData.residence_type,
        residence_since: formatGermanDateToUTC(
          modifiedWorkData.residence_since as string,
        ),
      },
    };
    setSignerData(updatedSigner);
    updateB2CCoSigner(activeApplication, accessToken, updatedSigner).then(
      () => {
        setLoading(false);
        setWorkData(true);
        setActiveTab('incomeData');
        handleSliderTabsClick(260);
        scrollToTop();
      },
    );
  };

  const submitIncomeData = async (modifiedIncomeData: IncomeTemplateData) => {
    setLoading(true);
    const updatedSigner = {
      ...signerData,
      income_and_cost_details: {
        ...signerData?.income_and_cost_details,
        income: {
          currency_code: 'EUR',
          units: modifiedIncomeData.income,
          nanos: '',
        },
        additional_income: {
          currency_code: 'EUR',
          units: modifiedIncomeData.additional_income,
          nanos: '',
        },
        housing_cost: {
          currency_code: 'EUR',
          units: modifiedIncomeData.housing_cost,
          nanos: '',
        },
        additional_cost: {
          currency_code: 'EUR',
          units: modifiedIncomeData.additional_cost,
          nanos: '',
        },
      },
    };
    setSignerData(updatedSigner);
    updateB2CCoSigner(activeApplication, accessToken, updatedSigner).then(
      () => {
        setLoading(false);
        setIncomeData(true);
        setActiveTab('bankData');
        handleSliderTabsClick(400);
        scrollToTop();
      },
    );
  };

  // Save contact Data
  const submitBankData = async (modifiedIBankData: BankTemplateData) => {
    setLoading(true);
    const bankDetails = await formatBankDetails(modifiedIBankData.iban);
    const updatedSigner = {
      ...signerData,
      person: {
        ...signerData?.person,
      },
      bank_account: {
        ...bankDetails,
        account_owner: modifiedIBankData?.account_owner,
      },
    };

    setSignerData(updatedSigner);
    updateB2CCoSigner(activeApplication, accessToken, updatedSigner).then(
      () => {
        setLoading(false);
        setBankData(true);
        setActiveTab('documentData');
        handleSliderTabsClick(450);
        scrollToTop();
      },
    );
  };

  // Save contact Data
  const submitDocumentData = async (
    modifiedIdentityData: DocumentDataSubmittedValues,
    goNext?: boolean,
    document?: string,
  ) => {
    setLoading(true);
    let updatedSigner;
    if (document === 'residence') {
      updatedSigner = {
        ...signerData,
        person: {
          ...signerData?.person,
          identity_documents: [
            {
              document_id: modifiedIdentityData.document_id,
              type: modifiedIdentityData.identity_choice,
            },
          ],
        },
      };
      setSignerData(updatedSigner);
      setDocumentData(true);
      updateB2CCoSigner(activeApplication, accessToken, updatedSigner).then(
        () => {
          if (goNext) navigate(nextRoute());
        },
      );
    }
  };

  const handleSliderTabsClick = (scrollValue: number) => {
    const container = document.getElementById('tabs-container');
    container?.scrollBy({
      left: scrollValue - container.scrollLeft,
      behavior: 'smooth',
    });
  };

  return (
    <div className='top-96'>
      <h1 className='title'>{getText('co_applicant_title')}</h1>
      <p className='content-normal dark-grey'>
        {getText('co_applicant_subTitle')}
      </p>
      <div className='tabs-container' id='tabs-container'>
        <TabItem
          title='Personendaten'
          id='personalData'
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          onClick={() => handleSliderTabsClick(0)}
          className={`${personalData ? 'success-tab' : ''}`}
        />
        <TabItem
          title='Kontaktdaten'
          id='contactData'
          activeTab={activeTab}
          disabled={
            !validateSignerData([coApplicant as any], activeApplication) &&
            !personalData
          }
          onClick={() => handleSliderTabsClick(0)}
          setActiveTab={setActiveTab}
          className={`${contactData ? 'success-tab' : ''}`}
        />
        <TabItem
          title='Arbeit, Familie & Wohnen'
          id='workData'
          activeTab={activeTab}
          disabled={
            !validateSignerData([coApplicant as any], activeApplication) &&
            !contactData
          }
          onClick={() => handleSliderTabsClick(120)}
          setActiveTab={setActiveTab}
          className={`${workData ? 'success-tab' : ''}`}
        />
        <TabItem
          title='Ein- & Ausgaben'
          id='incomeData'
          activeTab={activeTab}
          disabled={
            !validateSignerData([coApplicant as any], activeApplication) &&
            !workData
          }
          onClick={() => handleSliderTabsClick(260)}
          setActiveTab={setActiveTab}
          className={`${incomeData ? 'success-tab' : ''}`}
        />

        <TabItem
          title='Bank'
          id='bankData'
          disabled={
            !validateSignerData([coApplicant as any], activeApplication) &&
            !incomeData
          }
          activeTab={activeTab}
          onClick={() => handleSliderTabsClick(400)}
          setActiveTab={setActiveTab}
          className={`${bankData ? 'success-tab' : ''}`}
        />

        <TabItem
          title='Dokumentupload'
          id='documentData'
          activeTab={activeTab}
          disabled={
            !validateSignerData([coApplicant as any], activeApplication) &&
            !bankData
          }
          onClick={() => handleSliderTabsClick(430)}
          setActiveTab={setActiveTab}
          className={`${documentData ? 'success-tab' : ''}`}
        />
      </div>
      <div className='tab-content'>
        <TabContent id='personalData' activeTab={activeTab}>
          <PersonalTemplate
            tabButton
            onSubmit={submitPersonalData}
            privateCustomer={coApplicant}
            loading={loading}
            singer
          />
        </TabContent>
        <TabContent id='contactData' activeTab={activeTab}>
          <ContactTemplate
            tabButton
            onSubmit={submitContactData}
            privateCustomer={coApplicant}
            loading={loading}
            singer
          />
        </TabContent>
        <TabContent id='workData' activeTab={activeTab}>
          <WorkTemplate
            tabButton
            onSubmit={submitWorkData}
            privateCustomer={coApplicant}
            loading={loading}
          />
        </TabContent>
        <TabContent id='incomeData' activeTab={activeTab}>
          <IncomeTemplate
            tabButton
            onSubmit={submitIncomeData}
            privateCustomer={coApplicant}
            loading={loading}
          />
        </TabContent>
        <TabContent id='bankData' activeTab={activeTab}>
          <BankTemplate
            tabButton
            onSubmit={submitBankData}
            privateCustomer={coApplicant}
            loading={loading}
          />
        </TabContent>

        <TabContent id='documentData' activeTab={activeTab}>
          <DocumentTemplate
            coApplicant
            onSubmit={submitDocumentData}
            goBack={goBack}
            privateCustomer={coApplicant}
            loading={loading}
            setLoading={(value) => value !== undefined && setLoading(value)}
          />
        </TabContent>
      </div>
    </div>
  );
};

export default CoApplicant;
