import React from 'react';
import { getIdentityProvider } from '../../../../../utils/getter';
import { ReactComponent as IDnowStepOne } from '../../../components/icons/id-now-step-one.svg';
import { ReactComponent as IDnowStepThree } from '../../../components/icons/id-now-step-three.svg';
import { ReactComponent as IDnowStepTwo } from '../../../components/icons/id-now-step-two.svg';
import { ReactComponent as IDnowLogo } from '../../../components/icons/idnow.svg';
import { ReactComponent as WebIDLogo } from '../../../components/icons/web-id.svg';
import './id-now-info.css';

const IDNowInfo: React.FC = () => {
  return (
    <div className='idnow-info'>
      <h2 className='content-title align-center center top-72'>
        So funktioniert die Video-Legitimation mit{' '}
        {getIdentityProvider() === 'IDnow' ? (
          <IDnowLogo width='84px' height='29px' />
        ) : (
          <WebIDLogo width='84px' height='29px' />
        )}
      </h2>
      <div className='idnow-info-details'>
        <div className='idnow-info-steps'>
          <span className='idnow-step'>1</span>
          <IDnowStepOne />
          <p className='content-bold idnow-step-title'>Gerät auswählen</p>
          <p className='content-normal idnow-step-description dark-grey'>
            Wähle Dein bevorzugtes Gerät und halte deinen Ausweis bereit.
          </p>
        </div>
        <div className='idnow-info-steps'>
          <span className='idnow-step'>2</span>
          <IDnowStepTwo />
          <p className='content-bold idnow-step-title'>Video-Chat starten</p>
          <p className='content-normal idnow-step-description dark-grey'>
            Führe den Video-Chat durch und halte deinen Ausweis in die Kamera.
          </p>
        </div>
        <div className='idnow-info-steps'>
          <span className='idnow-step'>3</span>
          <IDnowStepThree />
          <p className='content-bold idnow-step-title'>
            Legitimation abschließen
          </p>
          <p className='content-normal idnow-step-description dark-grey'>
            Video-Ident-Signaturcode eingeben und Legitimation abschließen.
          </p>
        </div>
      </div>
    </div>
  );
};

export default IDNowInfo;
