import LayerOne from '../../../../styles/animated/credit-check-loader/Layer_1.svg';
import LayerTwo from '../../../../styles/animated/credit-check-loader/Layer_2.svg';
import LayerThree from '../../../../styles/animated/credit-check-loader/Layer_3.svg';
import LayerFour from '../../../../styles/animated/credit-check-loader/Layer_4.svg';
import LayerFive from '../../../../styles/animated/credit-check-loader/Layer_5.svg';
import LayerSix from '../../../../styles/animated/credit-check-loader/Layer_6.svg';
import './credit-check-loader.css';

const CreditCheckLoader = () => {
  return (
    <div className='animation-layout margin-auto'>
      <img
        src={LayerOne}
        alt='animation'
        className='credit-check-loader-animate'
      />
      <img
        src={LayerTwo}
        alt='animation'
        className='credit-check-loader-animate'
      />
      <img
        src={LayerThree}
        alt='animation'
        className='credit-check-loader-animate'
      />

      <img
        src={LayerFour}
        alt='animation'
        className='credit-check-loader-animate'
      />
      <img
        src={LayerFive}
        alt='animation'
        className='credit-check-loader-animate'
      />
      <img
        src={LayerSix}
        alt='animation'
        className='credit-check-loader-animate'
      />
    </div>
  );
};

export default CreditCheckLoader;
