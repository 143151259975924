import { confetti } from '@tsparticles/confetti';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteProps, useNavigate } from 'react-router-dom';
import { getDocument } from '../../../api/instant-lease-api';
import Layout from '../../../layout';
import { setNotification } from '../../../redux/cache-slice';
import { useAppDispatch } from '../../../redux/store';
import { RootState } from '../../../redux/types';
import sequenceConfig from '../../../sequence/sequence-config';
import { Document, Type } from '../../../types/instant-lease-api';
import { getAppStatus, getPartnerText, getText } from '../../../utils/getter';
import Navigation from '../../../utils/navigation';
import { formatUTCDateToGerman } from '../../../utils/sanitize-data';
import { validateSignerTerms } from '../../../utils/validatetor';
import ArrowList from '../../b2b/components/arrow-list/arrow-list';
import Button from '../../b2b/components/button/button';
import { ReactComponent as ArrowRight } from '../../b2b/components/icons/chevron-right.svg';
import SequencesPhases from '../../b2b/components/sequences-phases/sequences-phases';
import Spinner from '../../b2b/components/spinner/spinner';
import ButtonGroup from '../../b2b/molecules/button-group/button-group';
import SignersListAGB from '../../b2b/templates/signers-list-agb/signers-list-agb';
import SignersList from '../../b2b/templates/signers-list/signers-list';
import StatusPage from '../../b2b/templates/status-page/status-page';
import { ReactComponent as ContractIcon } from '../icons/contract-icon.svg';
import { ReactComponent as DownloadIcon } from '../icons/download-icon.svg';
import { ReactComponent as Failed } from '../images/credit-failed.svg';
import { ReactComponent as CreditPending } from '../images/credit-pending.svg';
import { ReactComponent as Error } from '../images/error.svg';
import { ReactComponent as Signed } from '../images/signed-contract.svg';
import './status-overview.css';

const LeaseAppStatusOverview: React.FC<RouteProps> = () => {
  // Store
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Dispatch hook
  const dispatch = useAppDispatch();

  // auth
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Auth Store
  const { emailVerified } = useSelector((state: RootState) => state.auth);

  // Sequence Store
  const { sequence, step } = useSelector((state: RootState) => state.sequence);

  // Navigation class
  const { nextRoute } = new Navigation();

  // Application status
  const status = getAppStatus(activeApplication);

  // Lease Application Type
  const type = activeApplication?.type as Type;

  // Sequence config
  const config = sequenceConfig();

  // is Signers accepted terms
  const isSignersAcceptedTerms = validateSignerTerms(activeApplication);

  // Navigate hook
  const navigate = useNavigate();

  // Check if document exists
  const isDocumentExist = (documentType: string): Document | undefined =>
    activeApplication?.registered_documents?.find(
      (doc) => doc.document_type === documentType,
    );

  // Download the requested from API
  const downloadFile = (document: Document | undefined) => {
    if (document) {
      const { mime_type, token, file_name } = document;
      getDocument(accessToken, activeApplication?.uuid || '', token, true)
        .then((response) => {
          const blob = window.URL.createObjectURL(
            new Blob([response], { type: mime_type }),
          );
          const link = window.document.createElement('a');
          link.href = blob;
          // link.download = file_name;
          link.setAttribute('download', file_name);
          link.click();
          window.open(blob, '_blank');
        })
        .catch(() => {
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
        });
    }
  };

  useEffect(() => {
    if (!emailVerified) {
      navigate('/active-applications/overview');
    }
  });

  useEffect(() => {
    if (status === 'Completed') {
      confetti('confetti-left', {
        count: 300,
        position: {
          x: 100,
          y: 80,
        },
        angle: 120,
        spread: 100,
        startVelocity: 100,
        decay: 0.9,
        gravity: 0,
        drift: 0,
        ticks: 10,
        colors: [
          '#031a6b',
          '#4ea699',
          '#5386e4',
          '#684aef',
          '#f29559',
          '#ef476f',
        ],
        shapes: ['circle', 'square', 'polygon'],
        scalar: 1,
        zIndex: 100,
        disableForReducedMotion: true,
      });

      confetti('confetti-right', {
        count: 300,
        position: {
          x: 0,
          y: 80,
        },
        angle: 30,
        spread: 100,
        startVelocity: 100,
        decay: 0.9,
        gravity: 0,
        drift: 0,
        ticks: 10,
        colors: [
          '#031a6b',
          '#4ea699',
          '#5386e4',
          '#684aef',
          '#f29559',
          '#ef476f',
        ],
        shapes: ['circle', 'square', 'polygon'],
        scalar: 1,
        zIndex: 100,
        disableForReducedMotion: true,
      });
    }
  }, [status]);

  const isButtonDisabled = (): boolean => {
    return !activeApplication?.uuid;
  };

  if (status === 'EsignaturePending') {
    return !isSignersAcceptedTerms ? <SignersListAGB /> : <SignersList />;
  }

  if (type === Type.B2C && status === 'FinalApprove') {
    return (
      <StatusPage
        heading='Finale Prüfung'
        subHeading='Fast geschafft !'
        image={<CreditPending />}
        content={[
          'Bitte hab noch ein wenig Geduld bis die Prufung deiner Unterlagen final abgeschlossen ist. Bitte beachte dabei unsere Geschäftszeiten (Montag• Donnerstag: 08:00 • 17:00 Uhr Freitag 06:00 • 15:00 Uhr).',
          getText('credit_pending_desc2'),
        ]}
        contentTitle='So geht’s weiter:'
      />
    );
  }

  if (activeApplication) {
    return (
      <Layout
        heading={
          status === 'Pending'
            ? 'Erstelle jetzt deinen Antrag'
            : config[sequence]?.title
        }
        subHeading={
          status === 'Pending'
            ? 'Du kannst den Antrag innerhalb der nächsten sieben Tage bearbeiten und vervollständigen.'
            : config[sequence]?.subTitle
        }
        className='grid'
        style={{ gridTemplateRows: '15% 60%' }}
      >
        {status === 'Completed' && (
          <div className='half-half-columns'>
            <Signed />
            <div style={{ marginTop: '92px' }}>
              <h2 className='content-bold' style={{ marginBottom: '24px' }}>
                Vertragsunterlagen
              </h2>
              <p className='content-normal dark-grey'>
                Um unsere Umwelt zu schützen, versenden wir den unterzeichneten
                Leasingvertrag <b>nicht mit der Post</b>. Du kannst dir deine
                Vertragsunterlagen hier oder jederzeit in deinem Online-Account
                herunterladen.
              </p>
              <div
                className='contract-download'
                style={{ marginBottom: '48px', marginTop: '16px' }}
              >
                <div
                  className='contract small'
                  role='presentation'
                  onClick={() => {
                    downloadFile(isDocumentExist('SIGNED_LEASING_CONTRACT'));
                  }}
                >
                  <div>
                    <ContractIcon />
                    <p className='content-normal'>
                      <span className='small-text'>
                        ABGESCHLOSSEN VERTRAG |{' '}
                        {formatUTCDateToGerman(
                          activeApplication?.customer
                            ?.document_signed_timestamp,
                        )}
                      </span>
                      <br />
                      <span className='content-bold'>
                        {activeApplication?.offer?.vehicle_data?.full_name}
                      </span>
                    </p>
                  </div>
                  <div>
                    <DownloadIcon />
                    <p className='content-bold'>Herunterladen</p>
                  </div>
                </div>
              </div>
              <div>
                <h2 className='content-bold' style={{ marginBottom: '24px' }}>
                  Die letzten Schritte
                </h2>
                <p className='content-normal dark-grey'>
                  Im letzten Schritt führen wir eine <b>finale Prüfung</b> der
                  Dokumente durch. Diese kann bis zu drei Werktage in Anspruch
                  nehmen. Anschließend senden wir <b>dir per E-Mail</b> die
                  Auftragsbestätigung und kontaktieren dich bzgl. der Anmeldung
                  und Übergabe des Fahrzeugs. Solltest du eine{' '}
                  <b>Mietsonderzahlung</b> gewählt haben, bitten wir dich, diese
                  zeitnah zu begleichen. Falls du noch Fragen hast, schaue gern
                  in unsere FAQ.
                </p>
              </div>
              <Button
                type='button'
                onClick={() =>
                  window.open('https://www.ald-online.de/faq', '_blank')
                }
                style={{ width: '100%', marginTop: '40px' }}
              >
                <span className='content-bold'>Weiter zu den FAQ</span>
              </Button>
            </div>
          </div>
        )}

        {status === 'Cancelled' && (
          <div className='half-half-columns'>
            <Failed />
            <div>
              <h2 className='content-bold'>Der Antrag wurde storniert</h2>

              <ul className='arrow-list'>
                <li className='arrow-list-item'>
                  <ArrowRight className='arrow-list-icon' />
                  <p>der letzte Antrag wurde von Dir selbst storniert</p>
                </li>
                <li className='arrow-list-item'>
                  <ArrowRight className='arrow-list-icon' />
                  <p>
                    möchtest Du ein anderes Fahrzeug leasen, dann{' '}
                    <a
                      href='https://www.ald-online.de/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      klicke hier
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        )}

        {status === 'Failed' && (
          <div className='half-half-columns'>
            <Failed />
            <div>
              <h2 className='content-bold'>Mögliche Gründe</h2>

              <ArrowList
                list={[
                  'Leider können wir, aufgrund der von dir getätigten Angaben, deinem Leasingwunsch nicht entsprechen.',
                ]}
              />
            </div>
          </div>
        )}

        {status === 'Timedout' && (
          <div className='grid-template'>
            <div className='half-half-columns'>
              <Failed />
              <div>
                <h2 className='content-bold'>
                  Ein Antrag ist aufgrund der von dir gemachten Angaben nicht
                  möglich.
                </h2>
                <ArrowList
                  list={[
                    `Leider können wir, aufgrund der von dir getätigten Angaben, deinem Leasingwunsch nicht entsprechen.. `,
                  ]}
                />
              </div>
            </div>
          </div>
        )}

        {status === 'Pending' && (
          <SequencesPhases
            sequence={sequence}
            partner={activeApplication.partner}
            type={activeApplication.type || Type.B2B}
            config={config}
            step={step}
          />
        )}

        {status === undefined && (
          <div className='half-half-columns'>
            <Error />
            <div>
              <h2 className='heading'>Das tut uns leid.</h2>
              <>
                <p className='content-normal'>
                  {getText('error_page_paragraph')}
                </p>
                <br />
                <p className='content-normal'>
                  E-Mail:{' '}
                  <a href={`mailto:${getPartnerText('contact_us_email')}`}>
                    {getPartnerText('contact_us_email')}
                  </a>
                </p>

                <p className='content-normal'>
                  Telefonisch zu unseren Geschäftszeiten: <br />
                  Tel: {getPartnerText('contact_us_number')} <br />
                  Mo – Do: 8:00 – 18:00 Uhr, Fr: 8:00– 16:00 Uhr
                </p>
              </>
            </div>
          </div>
        )}

        {status !== 'Completed' &&
          status !== 'Failed' &&
          status !== 'Cancelled' &&
          status !== undefined && (
            <ButtonGroup
              className='back-next medium'
              type='back-next'
              buttonOneProps={{
                theme: 'call-to-action',
                type: 'button',
                onClick: () => navigate(nextRoute()),
                disabled: isButtonDisabled(),
                children: activeApplication?.uuid ? 'weiter' : <Spinner />,
              }}
            />
          )}
      </Layout>
    );
  }

  return <></>;
};

export default LeaseAppStatusOverview;
