import React from 'react';
import './tab-item.css';

interface TabItemProps {
  id: string;
  title: string;
  activeTab: string;
  className?: string;
  setActiveTab?: (id: string) => void;
  disabled?: boolean;
  onClick?: () => void;
}

const TabItem: React.FC<TabItemProps> = ({
  id,
  title,
  activeTab,
  setActiveTab,
  className,
  disabled,
  onClick,
}) => {
  const handleClick = () => {
    if (setActiveTab) setActiveTab(id);
  };

  return (
    <div
      className={`tab-item ${className} ${
        activeTab === id ? 'active-tab content-bold' : 'content-small'
      } ${disabled ? 'disable-tab' : ''}`}
      id={id}
      onClick={onClick}
      data-testid={`tab-item-${id}`}
    >
      <div
        role='presentation'
        data-testid={`tab-title-${id}`}
        onClick={handleClick}
        className={`tab-title ${disabled ? 'disable-tab' : ''}`}
      >
        {title}
      </div>
    </div>
  );
};

export default TabItem;
