import { yupResolver } from '@hookform/resolvers/yup';
import { unwrapResult } from '@reduxjs/toolkit';
import ls from 'localstorage-slim';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { cancelLeaseApplication } from '../../../../api/instant-lease-api';
import Layout from '../../../../layout';
import {
  hasTransparencyRegister,
  setNotification,
} from '../../../../redux/cache-slice';
import {
  setTradeRegistry,
  updateCustomerData,
} from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  Company,
  Individual,
  LeaseApplication,
  LegalForm,
} from '../../../../types/instant-lease-api';
import {
  getBundeslands,
  getCommercialRegisterHeadlineText,
  getPartnerText,
  getText,
} from '../../../../utils/getter';
import {
  hasCommercialRegisterValidation,
  hideCommercialRegisterSection,
  hideTransparenzregisterSection,
} from '../../../../utils/helper';
import { errorLogging } from '../../../../utils/logging';
import Navigation from '../../../../utils/navigation';
import FormLayout from '../../../common/form-layout/form-layout';
import InputField from '../../../common/input-field/input-field';
import SelectBox from '../../../common/select-box/select-box';
import Button from '../../components/button/button';
import Modal from '../../components/modal/modal';
import RadioContainer from '../../components/radio-container/radio-container';
import Radio from '../../components/radio/radio';
import ButtonGroup from '../../molecules/button-group/button-group';

interface CommercialRegisterProps {
  getInitialValue: (leaseApp: LeaseApplication | null) => Company | Individual;
}

const CmTrRegistry: React.FC<CommercialRegisterProps> = ({
  getInitialValue,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Loading State
  const [loading, setLoading] = useState<boolean>();

  // Cache Storage
  const cache = useSelector((state: RootState) => state.cache);

  // dispatch hook
  const dispatch = useAppDispatch();

  // Histroy Hook
  const navigate = useNavigate();

  // Navigation
  const { nextRoute } = new Navigation();

  // Get initial value from localstorage
  const initialValueTR = ls.get('tr');

  // Answer State
  const [answerTR, setAnswerTR] = useState<boolean | undefined>(
    cache?.hasTransparencyRegister ||
      (initialValueTR !== null ? initialValueTR === 'true' : undefined),
  );

  // Value of transpert register
  const [checkboxValue, setCheckboxValue] = useState(answerTR ? 'yes' : 'no');

  // modal visibility
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  // Handle checkbox changes
  const handleChangeCheckboxTR = (selection: boolean) => () => {
    dispatch(hasTransparencyRegister(selection));
    setAnswerTR(selection);
    setCheckboxValue(selection ? 'yes' : 'no');
  };

  // Validation Schema
  const validationSchema = yup.object().shape({
    trade_registry_number: yup.string().when('any', {
      is: hasCommercialRegisterValidation(activeApplication),
      then: (schema) => schema.required(getText('validation_number')),
      otherwise: (schema) => schema.notRequired(),
    }),
    local_court: yup.string().when('any', {
      is: hasCommercialRegisterValidation(activeApplication),
      then: (schema) => schema.min(2, 'Pflichtfeld').required('Pflichtfeld'),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  // React form hook
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
  });

  // Get initial values
  useEffect(() => {
    const company = getInitialValue(activeApplication);
    if (company && (company.trade_registry_number || company.local_court)) {
      setValue('trade_registry_number', company?.trade_registry_number || '');
      setValue('local_court', company?.local_court || '');
      trigger();
    }
  }, [getInitialValue, activeApplication, setValue, trigger]);

  // Get text based on legal entity of the company
  const getTitle = (): string => {
    let title = '';
    if (
      !hideCommercialRegisterSection(activeApplication) &&
      !hideTransparenzregisterSection(activeApplication)
    ) {
      title = `${
        getCommercialRegisterHeadlineText(activeApplication).headline
      } und Transparenzregister`;
    } else if (hideCommercialRegisterSection(activeApplication)) {
      title = 'Transparenzregister';
    } else if (hideTransparenzregisterSection(activeApplication)) {
      title = `${
        getCommercialRegisterHeadlineText(activeApplication).headline
      } `;
    }
    return title;
  };

  // Cancel leasing application
  const cancelCurrentLeaseApp = () => {
    cancelLeaseApplication(accessToken, activeApplication?.uuid as string)
      .then(() => {
        setModalVisibility(false);
        navigate('/cancel-lease');
      })
      .catch();
  };

  // Disable weiter button
  const disableButton = (): boolean => {
    if (!checkboxValue && !hideTransparenzregisterSection(activeApplication))
      return true;
    return false;
  };

  // Submit values
  const submit = ({
    trade_registry_number,
    local_court,
  }: {
    trade_registry_number?: string | undefined;
    local_court?: string | undefined;
  }) => {
    setLoading(true);
    if (trade_registry_number && local_court) {
      dispatch(
        updateCustomerData({
          accessToken,
          leaseApplicationId: activeApplication?.uuid || '',
          inputValue: {
            trade_registry_number,
            local_court,
          },
          path: APIPaths.TRADE_REGISTRY,
        }),
      )
        .then(unwrapResult)
        .then(() => {
          setLoading(false);
          dispatch(setTradeRegistry(trade_registry_number));
          navigate(nextRoute());
        })
        .catch(() => {
          setLoading(false);
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
          errorLogging(
            new Error(
              `unable to submit trade registers data to user with application id ${activeApplication?.uuid}`,
            ),
          );
        });
    } else {
      setLoading(false);
      navigate(nextRoute());
    }
  };

  return (
    <Layout heading={getTitle()} stepStatus>
      {!hideCommercialRegisterSection(activeApplication) && (
        <FormLayout
          sectionTitle={
            getCommercialRegisterHeadlineText(activeApplication).headline
          }
        >
          <form onSubmit={handleSubmit(submit)}>
            <div>
              <InputField
                label={
                  getCommercialRegisterHeadlineText(activeApplication)
                    .tradeRegistrytext
                }
                extraLabel={
                  !hasCommercialRegisterValidation(activeApplication)
                    ? ' (optional)'
                    : ''
                }
                {...register('trade_registry_number')}
                error={errors.trade_registry_number?.message}
              />
              {activeApplication?.customer?.company?.legal_form ===
              LegalForm.STIFTUNG ? (
                <SelectBox
                  initialValue={watch('local_court')}
                  setValueCallBack={(value) => {
                    setValue('local_court', value, { shouldValidate: true });
                    trigger();
                  }}
                  label={
                    getCommercialRegisterHeadlineText(activeApplication)
                      .localCourtText
                  }
                  placeholder={
                    getCommercialRegisterHeadlineText(activeApplication)
                      .localCourtText
                  }
                  name='local_court'
                  options={getBundeslands}
                  hasError={!!errors.local_court?.message}
                  errorMessage={errors.local_court?.message}
                />
              ) : (
                <InputField
                  label={
                    getCommercialRegisterHeadlineText(activeApplication)
                      .localCourtText
                  }
                  extraLabel={
                    !hasCommercialRegisterValidation(activeApplication)
                      ? ' (optional)'
                      : ''
                  }
                  {...register('local_court')}
                  error={errors.local_court?.message}
                />
              )}

              {hasCommercialRegisterValidation(activeApplication) && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '10px',
                    color: '#5386e4',
                  }}
                >
                  <span
                    className='button-text'
                    role='presentation'
                    onClick={() => {
                      setModalVisibility(true);
                    }}
                  >
                    Nicht im Register eingetragen
                  </span>
                </div>
              )}
            </div>
          </form>
        </FormLayout>
      )}
      {!hideTransparenzregisterSection(activeApplication) && (
        <div className='top-36'>
          <label className='label content-normal dark-grey'>
            Ist dein Unternehmen nach §§ 20,21 GwG transparenzregisterpflichtig?
          </label>
          <div className='top-36'>
            <RadioContainer>
              <Radio
                checked={checkboxValue === 'yes'}
                onChange={handleChangeCheckboxTR(true)}
              >
                <p className='content-normal'>Ja</p>
              </Radio>
              <Radio
                checked={checkboxValue === 'no'}
                onChange={handleChangeCheckboxTR(false)}
              >
                <p className='content-normal'>Nein</p>
              </Radio>
            </RadioContainer>
          </div>
        </div>
      )}
      <ButtonGroup
        className='back-next medium'
        type='back-next'
        data-testid='disabled-forward-button'
        buttonOneProps={{
          type: 'button',
          onClick: handleSubmit(submit),
          name: 'form-submit',
          loading,
          disabled: disableButton(),
          dataTestId: 'next',
          children: 'weiter',
        }}
      />
      <Modal
        isOpen={modalVisibility}
        onClose={() => setModalVisibility(false)}
        type='middle'
        direction='down'
      >
        <h2 className='heading'>Kein Registereintrag?</h2>
        <p className='content-normal'>{getText('no_register')}</p>

        <Button
          theme='danger'
          type='button'
          className='back-next top-24 margin-auto '
          onClick={() => cancelCurrentLeaseApp()}
        >
          {getPartnerText('remove_contract')}
        </Button>
      </Modal>
    </Layout>
  );
};

export default CmTrRegistry;
