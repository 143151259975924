import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDocument, submitTrigger } from '../../../../api/instant-lease-api';
import Layout from '../../../../layout';
import { setNotification } from '../../../../redux/cache-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import sequenceConfig from '../../../../sequence/sequence-config';
import { Document } from '../../../../types/instant-lease-api';
import {
  getChildrenByNumber,
  getCoApplicant,
  getEmploymentByType,
  getMainApplicant,
  getMaritalStatusByType,
  getResidenceByType,
  getText,
} from '../../../../utils/getter';
import { mapAddressField } from '../../../../utils/helper';
import { errorLogging } from '../../../../utils/logging';
import Navigation from '../../../../utils/navigation';
import ButtonGroup from '../../../b2b/molecules/button-group/button-group';
import ConfirmSection from '../../../b2b/molecules/confirm-section/confirm-section';
import './data-summary.css';

const DataSummary: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Dispatch hook
  const dispatch = useAppDispatch();

  // Init history hook (used in nav)
  const navigate = useNavigate();

  // Navigation class
  const { nextRoute } = new Navigation();

  // Loading State
  const [loading, setLoading] = useState<boolean>(false);

  // Next page
  const next = async () => {
    setLoading(true);
    if (activeApplication) {
      submitTrigger(
        accessToken,
        activeApplication.uuid,
        'confirm_customer_data',
      )
        .then(() => {
          setLoading(false);
          navigate(nextRoute());
        })
        .catch(() => {
          setLoading(false);
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
          errorLogging(
            new Error(
              `unable to submit data summary to user with application id ${activeApplication?.uuid}`,
            ),
          );
        });
    }
  };

  // Get person
  const privateCustomer = activeApplication?.customer?.private;

  const hasPartner =
    privateCustomer?.signers && privateCustomer?.signers?.length > 1;

  const mainSigner = getMainApplicant(privateCustomer?.signers);

  const partner = getCoApplicant(privateCustomer?.signers);

  const mainSignerDocuments = activeApplication?.registered_documents?.filter(
    (document) => {
      if (
        mainSigner?.person?.documents?.find((token) => token === document.token)
      ) {
        return document;
      }
      return false;
    },
  );

  const coSignerDocuments = activeApplication?.registered_documents?.filter(
    (document) => {
      if (
        partner?.person?.documents?.find((token) => token === document.token)
      ) {
        return document;
      }
      return false;
    },
  );

  // Getting step name by number
  const getStepNumberByName = (key: string): number =>
    sequenceConfig(activeApplication as any)[0].steps.findIndex(
      (step) => step.key === key,
    );

  // Check if document exists
  const isDocumentExist = (documentType: string): Document | undefined =>
    activeApplication?.registered_documents?.find(
      (doc) => doc.document_type === documentType,
    );

  // Open file in a new tab
  const openFile = (document: Document | undefined) => {
    if (document) {
      getDocument(accessToken, activeApplication?.uuid || '', document.token)
        .then((response) => {
          const blob = window.URL.createObjectURL(
            new Blob([response], { type: document.mime_type }),
          );
          const link = window.document.createElement('a');
          link.href = blob;
          window.open(link.href, '_blank', 'noopener,noreferrer');
        })
        .catch(() => {
          dispatch(
            setNotification({
              notificationVisibility: true,
              notificationHasError: true,
              notificationTitle: 'Das tut uns leid.',
              notificationBody: getText('error_unable_to_process_data'),
            }),
          );
        });
    }
  };

  const signerIdentityDocument = () => {
    const docID =
      mainSigner?.person?.identity_documents &&
      mainSigner?.person?.identity_documents?.length > 0 &&
      mainSigner?.person?.identity_documents[0]?.document_id;

    return activeApplication?.registered_documents?.find(
      (doc) => doc.token === docID,
    );
  };

  const partnerIdentityDocument = () => {
    const docID =
      partner?.person?.identity_documents &&
      partner?.person?.identity_documents?.length > 0 &&
      partner?.person?.identity_documents[0]?.document_id;

    return activeApplication?.registered_documents?.find(
      (doc) => doc.token === docID,
    );
  };

  const ellipsisFileName = (fileName: string): string => {
    if (fileName.length > 35) {
      return (
        fileName.substr(0, 10) +
        '...' +
        fileName.substr(fileName.length - 10, fileName.length)
      );
    }
    return fileName;
  };

  return (
    <Layout
      heading='Fast Geschafft!'
      subHeading='Bevor es gleich weitergeht, kontrolliere bitte deine Eingaben.'
      stepStatus
    >
      <h2 className='content-title summary-section-title'>
        Deine persönlichen Daten
      </h2>
      <ConfirmSection
        sectionTitle='Persönliche Angaben'
        step={getStepNumberByName('personalInformation')}
        elements={[
          {
            title: 'Name',
            value: `${privateCustomer?.person?.first_name} ${privateCustomer?.person?.last_name}`,
            titleClassName: 'small-text title-style',
            valueClassName: 'small-text',
          },
          {
            title: 'Meldeadresse',
            titleClassName: 'small-text',
            valueClassName: 'small-text',
            value: (
              <>
                {mapAddressField(
                  privateCustomer?.person?.contact_details,
                  'street_line',
                )}{' '}
                {mapAddressField(
                  privateCustomer?.person?.contact_details,
                  'house_number',
                )}
                <br />
                {mapAddressField(
                  privateCustomer?.person?.contact_details,
                  'postal_code',
                )}{' '}
                {mapAddressField(
                  privateCustomer?.person?.contact_details,
                  'locality',
                )}
                <br />
                {mapAddressField(
                  privateCustomer?.person?.contact_details,
                  'additional_lines',
                )}
              </>
            ),
          },
          {
            title: 'Geburtsdaten',
            titleClassName: 'small-text',
            valueClassName: 'small-text',
            value: (
              <>
                {privateCustomer?.person?.date_of_birth} in{' '}
                {privateCustomer?.person?.birth_place}
              </>
            ),
          },
        ]}
      />

      <ConfirmSection
        sectionTitle='Kontakt'
        step={getStepNumberByName('contact')}
        elements={[
          {
            title: 'Mobilnummer',
            titleClassName: 'small-text',
            valueClassName: 'small-text',
            value: (
              <>
                {privateCustomer?.person?.contact_details?.phone_numbers
                  ? privateCustomer?.person?.contact_details?.phone_numbers[0]
                      .value
                  : ''}
              </>
            ),
          },
          {
            title: 'E-Mail Adresse',
            titleClassName: 'small-text',
            valueClassName: 'small-text',
            value: (
              <>
                {privateCustomer?.person?.contact_details?.emails
                  ? privateCustomer?.person?.contact_details?.emails[0].value
                  : ''}
              </>
            ),
          },
        ]}
      />

      <ConfirmSection
        sectionTitle='Bankverbindung'
        step={getStepNumberByName('bank')}
        elements={[
          {
            title: 'IBAN',
            titleClassName: 'small-text',
            valueClassName: 'small-text',
            value: `${privateCustomer?.bank_account?.iban}`,
          },
          {
            title: 'Bankinstitut',
            titleClassName: 'small-text',
            valueClassName: 'small-text',
            value: `${privateCustomer?.bank_account?.bank_name}`,
          },
        ]}
      />

      <h2 className='content-title summary-section-title'>
        Arbeit, Familie & Wohnen
      </h2>
      <ConfirmSection
        sectionTitle='Arbeit'
        step={getStepNumberByName('workFamilyHousing')}
        elements={[
          {
            title: 'Beschäftigung',
            titleClassName: 'small-text',
            valueClassName: 'small-text',
            value: (
              <>
                {
                  getEmploymentByType(
                    privateCustomer?.living_details?.employment_type || '',
                  )?.label
                }{' '}
                | Seit {privateCustomer?.living_details?.employment_since}{' '}
                <br />
              </>
            ),
          },
        ]}
      />

      <ConfirmSection
        sectionTitle='Familie'
        step={getStepNumberByName('workFamilyHousing')}
        elements={[
          {
            title: 'Familienstand & Kinder',
            titleClassName: 'small-text',
            valueClassName: 'small-text',
            value: (
              <>
                {
                  getMaritalStatusByType(
                    privateCustomer?.living_details?.marital_status || '',
                  )?.label
                }{' '}
                |{' '}
                {
                  getChildrenByNumber(
                    privateCustomer?.living_details?.number_of_children,
                  )?.value
                }{' '}
                unterhaltspflichtige Kinder <br />
              </>
            ),
          },
        ]}
      />

      <ConfirmSection
        sectionTitle='Wohnen'
        step={getStepNumberByName('workFamilyHousing')}
        elements={[
          {
            title: 'Wohnsituation',
            titleClassName: 'small-text',
            valueClassName: 'small-text',
            value: (
              <>
                {
                  getResidenceByType(
                    privateCustomer?.living_details?.residence_type,
                  )?.label
                }
                <br />
                Seit {privateCustomer?.living_details?.residence_since}
                <br />
              </>
            ),
          },
        ]}
      />

      <h2 className='content-title summary-section-title'>Budget</h2>
      <ConfirmSection
        sectionTitle='Einnahmen'
        step={getStepNumberByName('incomeAndExpenses')}
        elements={[
          {
            title: 'Gesamte Einnahmen',
            titleClassName: 'small-text',
            valueClassName: 'small-text',
            value: `${
              parseInt(
                privateCustomer?.income_and_cost_details?.income?.units || '0',
              ) +
              parseInt(
                privateCustomer?.income_and_cost_details?.additional_income
                  ?.units || '0',
              )
            }${'€ / Monat'}`,
          },
        ]}
      />

      <ConfirmSection
        sectionTitle='Ausgaben'
        step={getStepNumberByName('incomeAndExpenses')}
        elements={[
          {
            title: `Gesamte Ausgaben`,
            titleClassName: 'small-text',
            valueClassName: 'small-text',
            value: `${
              parseInt(
                privateCustomer?.income_and_cost_details?.housing_cost?.units ||
                  '0',
              ) +
              parseInt(
                privateCustomer?.income_and_cost_details?.additional_cost
                  ?.units || '0',
              )
            }${'€ / Monat'}`,
          },
        ]}
      />

      <ConfirmSection
        sectionTitle=''
        step={getStepNumberByName('incomeAndExpenses')}
        elements={[
          {
            title: `Budget`,
            titleClassName: 'small-text',
            valueClassName: 'small-text',
            value: `${
              parseInt(
                privateCustomer?.income_and_cost_details?.income?.units || '0',
              ) +
              parseInt(
                privateCustomer?.income_and_cost_details?.additional_income
                  ?.units || '0',
              ) -
              parseInt(
                privateCustomer?.income_and_cost_details?.housing_cost?.units ||
                  '0',
              ) +
              parseInt(
                privateCustomer?.income_and_cost_details?.additional_cost
                  ?.units || '0',
              )
            } ${'€'}`,
          },
        ]}
      />

      <h2 className='content-title summary-section-title'>
        Unterzeichner Dokumente
      </h2>
      {mainSignerDocuments && mainSignerDocuments.length > 0 ? (
        <ConfirmSection
          sectionTitle='Dokumenten-Upload'
          step={getStepNumberByName('uploadDocuments')}
          elements={mainSignerDocuments?.map((document) => {
            const incomeDoc =
              document.document_type === 'PROOF_OF_INCOME'
                ? document
                : undefined;
            return {
              title: 'Einkommensnachweise',
              value: `${
                incomeDoc ? ellipsisFileName(incomeDoc.file_name) : ''
              }`,
              titleClassName: 'small-text',
              valueClassName: 'small-text',
              onClick: () => openFile(incomeDoc),
            };
          })}
        />
      ) : (
        <ConfirmSection
          sectionTitle='Dokumenten-Upload'
          step={getStepNumberByName('uploadDocuments')}
          elements={activeApplication?.registered_documents?.map((document) => {
            const incomeDoc =
              document.document_type === 'PROOF_OF_INCOME'
                ? document
                : undefined;
            return {
              title: 'Einkommensnachweise',
              value: `${
                incomeDoc ? ellipsisFileName(incomeDoc.file_name) : ''
              }`,
              titleClassName: 'small-text',
              valueClassName: 'small-text',
              onClick: () => openFile(incomeDoc),
            };
          })}
        />
      )}

      {isDocumentExist('PROOF_OF_RESIDENCE') && (
        <ConfirmSection
          sectionTitle='Ausweisdokument'
          step={getStepNumberByName('uploadDocuments')}
          elements={[
            {
              title: 'Meldebestätigung',
              value: ellipsisFileName(
                signerIdentityDocument()?.file_name || '',
              ),
              condition: !!signerIdentityDocument(),
              onClick: () => openFile(signerIdentityDocument()),
            },
          ]}
        />
      )}
      <hr />

      {hasPartner && (
        <>
          <h2 className='content-title summary-section-title'>
            Mitantragssteller/in Persönlichen Daten
          </h2>
          <ConfirmSection
            sectionTitle='Persönliche Angaben'
            step={getStepNumberByName('coApplicant')}
            elements={[
              {
                title: 'Namen',
                value: `${partner?.person?.first_name} ${partner?.person?.last_name}`,
                titleClassName: 'small-text title-style',
                valueClassName: 'small-text',
              },
              {
                title: 'Meldeadresse',
                titleClassName: 'small-text',
                valueClassName: 'small-text',
                value: (
                  <>
                    {mapAddressField(
                      partner?.person?.contact_details,
                      'street_line',
                    )}{' '}
                    {mapAddressField(
                      partner?.person?.contact_details,
                      'house_number',
                    )}
                    <br />
                    {mapAddressField(
                      partner?.person?.contact_details,
                      'postal_code',
                    )}{' '}
                    {mapAddressField(
                      partner?.person?.contact_details,
                      'locality',
                    )}
                    <br />
                    {mapAddressField(
                      partner?.person?.contact_details,
                      'additional_lines',
                    )}
                  </>
                ),
              },
              {
                title: 'Geburtsdaten',
                titleClassName: 'small-text',
                valueClassName: 'small-text',
                value: (
                  <>
                    {partner?.person?.date_of_birth} in{' '}
                    {partner?.person?.birth_place}
                  </>
                ),
              },
            ]}
          />

          <ConfirmSection
            sectionTitle='Kontakt'
            step={getStepNumberByName('coApplicant')}
            elements={[
              {
                title: 'Mobilnummer',
                titleClassName: 'small-text',
                valueClassName: 'small-text',
                value: (
                  <>
                    {partner?.person?.contact_details?.phone_numbers &&
                    partner?.person?.contact_details?.phone_numbers.length
                      ? partner?.person?.contact_details?.phone_numbers[0].value
                      : ''}
                  </>
                ),
              },
              {
                title: 'E-Mail Adresse',
                titleClassName: 'small-text',
                valueClassName: 'small-text',
                value: (
                  <>
                    {partner?.person?.contact_details?.emails &&
                    partner?.person?.contact_details?.emails.length
                      ? partner?.person?.contact_details?.emails[0].value
                      : ''}
                  </>
                ),
              },
            ]}
          />

          <ConfirmSection
            sectionTitle='Bankverbindung'
            step={getStepNumberByName('coApplicant')}
            elements={[
              {
                title: 'IBAN',
                titleClassName: 'small-text',
                valueClassName: 'small-text',
                value: `${partner?.bank_account?.iban}`,
              },
              {
                title: 'Bankinstitut',
                titleClassName: 'small-text',
                valueClassName: 'small-text',
                value: `${partner?.bank_account?.bank_name}`,
              },
            ]}
          />

          <h2 className='content-title summary-section-title'>
            Arbeit, Familie & Wohnen
          </h2>
          <ConfirmSection
            sectionTitle='Arbeit'
            step={getStepNumberByName('coApplicant')}
            elements={[
              {
                title: 'Beschäftigung',
                titleClassName: 'small-text',
                valueClassName: 'small-text',
                value: (
                  <>
                    {
                      getEmploymentByType(
                        partner?.living_details?.employment_type || '',
                      )?.label
                    }{' '}
                    | Seit {partner?.living_details?.employment_since} <br />
                  </>
                ),
              },
            ]}
          />

          <ConfirmSection
            sectionTitle='Familie'
            step={getStepNumberByName('coApplicant')}
            elements={[
              {
                title: 'Familienstand & Kinder',
                titleClassName: 'small-text',
                valueClassName: 'small-text',
                value: (
                  <>
                    {
                      getMaritalStatusByType(
                        partner?.living_details?.marital_status || '',
                      )?.label
                    }{' '}
                    |{' '}
                    {
                      getChildrenByNumber(
                        partner?.living_details?.number_of_children,
                      )?.value
                    }{' '}
                    unterhaltspflichtige Kinder <br />
                  </>
                ),
              },
            ]}
          />

          <ConfirmSection
            sectionTitle='Wohnen'
            step={getStepNumberByName('coApplicant')}
            elements={[
              {
                title: 'Wohnsituation',
                titleClassName: 'small-text',
                valueClassName: 'small-text',
                value: (
                  <>
                    {
                      getResidenceByType(
                        partner?.living_details?.residence_type,
                      )?.label
                    }
                    <br />
                    Seit {partner?.living_details?.residence_since}
                    <br />
                  </>
                ),
              },
            ]}
          />

          <h2 className='content-title summary-section-title'>Budget</h2>
          <ConfirmSection
            sectionTitle='Einnahmen'
            step={getStepNumberByName('coApplicant')}
            elements={[
              {
                title: 'Gesamte Einnahmen',
                titleClassName: 'small-text',
                valueClassName: 'small-text',
                value: `${
                  parseInt(
                    partner?.income_and_cost_details?.income?.units || '0',
                  ) +
                  parseInt(
                    partner?.income_and_cost_details?.additional_income
                      ?.units || '0',
                  )
                }${'€ / Monat'}`,
              },
            ]}
          />

          <ConfirmSection
            sectionTitle='Ausgaben'
            step={getStepNumberByName('coApplicant')}
            elements={[
              {
                title: `Gesamte Ausgaben`,
                titleClassName: 'small-text',
                valueClassName: 'small-text',
                value: `${
                  parseInt(
                    partner?.income_and_cost_details?.housing_cost?.units ||
                      '0',
                  ) +
                  parseInt(
                    partner?.income_and_cost_details?.additional_cost?.units ||
                      '0',
                  )
                }${'€ / Monat'}`,
              },
            ]}
          />

          <ConfirmSection
            sectionTitle=''
            step={getStepNumberByName('incomeAndExpenses')}
            elements={[
              {
                title: `Budget`,
                titleClassName: 'small-text',
                valueClassName: 'small-text',
                value: `${
                  parseInt(
                    partner?.income_and_cost_details?.income?.units || '0',
                  ) +
                  parseInt(
                    partner?.income_and_cost_details?.additional_income
                      ?.units || '0',
                  ) -
                  parseInt(
                    partner?.income_and_cost_details?.housing_cost?.units ||
                      '0',
                  ) +
                  parseInt(
                    partner?.income_and_cost_details?.additional_cost?.units ||
                      '0',
                  )
                } ${'€'}`,
              },
            ]}
          />

          <h2 className='content-title summary-section-title'>
            Unterzeichner Dokumente
          </h2>
          <ConfirmSection
            sectionTitle='Dokumenten-Upload'
            step={getStepNumberByName('coApplicant')}
            elements={coSignerDocuments?.map((document) => {
              const incomeDoc =
                document.document_type === 'PROOF_OF_INCOME'
                  ? document
                  : undefined;
              return {
                title: 'Einkommensnachweise',
                value: `${
                  incomeDoc ? ellipsisFileName(incomeDoc.file_name) : ''
                }`,
                titleClassName: 'small-text',
                valueClassName: 'small-text',
                onClick: () => openFile(incomeDoc),
              };
            })}
          />

          {partnerIdentityDocument() && (
            <ConfirmSection
              sectionTitle='Ausweisdokument'
              step={getStepNumberByName('coApplicant')}
              elements={[
                {
                  title: 'Meldebestätigung',
                  value: ellipsisFileName(
                    partnerIdentityDocument()?.file_name || '',
                  ),
                  condition: !!partnerIdentityDocument(),
                  onClick: () => openFile(partnerIdentityDocument()),
                },
              ]}
            />
          )}
        </>
      )}

      <ButtonGroup
        type='back-next'
        className='back-next'
        buttonOneProps={{
          type: 'submit',
          loading,
          onClick: next,
          name: 'form-submit',
          dataTestId: 'next',
          children: 'weiter',
        }}
      />
    </Layout>
  );
};
export default DataSummary;
