import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { EDevice } from '../../../../redux/sequence/enums';
import { RootState } from '../../../../redux/types';
import WorkFamilyHousingDropdown from '../../../../types/dropdown';
import { PrivateCustomer } from '../../../../types/instant-lease-api';
import { getText } from '../../../../utils/getter';
import Button from '../../../b2b/components/button/button';
import ButtonGroup from '../../../b2b/molecules/button-group/button-group';
import FormLayout from '../../../common/form-layout/form-layout';
import InputDate from '../../../common/input-field/input-date';
import SelectBox from '../../../common/select-box/select-box';

interface WorkTemplateDataProps {
  onSubmit: (owner: WorkTemplateData) => void;
  tabButton: boolean;
  privateCustomer: PrivateCustomer | undefined;
  loading?: boolean;
}
export interface WorkTemplateData {
  employment_type: string;
  employment_since: string;
  marital_status: string;
  number_of_children: number;
  residence_type: string;
  residence_since: string;
}

const WorkTemplate: React.FC<WorkTemplateDataProps> = ({
  privateCustomer,
  onSubmit,
  tabButton,
  loading,
}) => {
  // sequence Storage
  const { device } = useSelector((state: RootState) => state.sequence);

  // Get Birth Date from the lease application
  const birthDate = privateCustomer?.person?.date_of_birth;

  // Validation Schema
  const validationSchema = yup.object().shape({
    employment_type: yup.string().required('Pflichtfeld'),
    employment_since: yup
      .string()
      .required('Pflichtfeld')
      .matches(
        /^(((0?[1-9]|[12]\d|3[01])\.(0[13578]|[13578]|1[02])\.((1[6-9]|[2-9]\d)\d{2}))|((0?[1-9]|[12]\d|30)\.(0[13456789]|[13456789]|1[012])\.((1[6-9]|[2-9]\d)\d{2}))|((0?[1-9]|1\d|2[0-8])\.0?2\.((1[6-9]|[2-9]\d)\d{2}))|(29\.0?2\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/,
        'Bitte Datum eintragen (TT.MM.JJJJ)',
      )
      .test(
        'maxDateToday',
        'Das Datum darf nicht in der Zukunft liegen',
        (value) => {
          return !value || moment(value, 'DD.MM.YYYY') <= moment();
        },
      ),
    marital_status: yup.string().required('Pflichtfeld'),
    number_of_children: yup.number().required('Pflichtfeld'),
    residence_type: yup.string().required('Pflichtfeld'),
    residence_since: yup
      .string()
      .required('Pflichtfeld')
      .matches(
        /^(((0?[1-9]|[12]\d|3[01])\.(0[13578]|[13578]|1[02])\.((1[6-9]|[2-9]\d)\d{2}))|((0?[1-9]|[12]\d|30)\.(0[13456789]|[13456789]|1[012])\.((1[6-9]|[2-9]\d)\d{2}))|((0?[1-9]|1\d|2[0-8])\.0?2\.((1[6-9]|[2-9]\d)\d{2}))|(29\.0?2\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/,
        'Bitte Datum eintragen (TT.MM.JJJJ)',
      )
      .test({
        name: 'test-residence',
        message: 'Muss nach Ihrem Geburtsdatum liegen',
        test: (residence_since) =>
          moment(residence_since, 'DD.MM.YYYY').year() >=
          moment(birthDate, 'DD.MM.YYYY').year(),
      })
      .test({
        name: 'maxDateToday',
        message: 'Das Einzugsdatum darf nicht in der Zukunft liegen',
        test: (value) => {
          return !value || moment(value, 'DD.MM.YYYY') <= moment();
        },
      }),
  });

  // useForm hook
  const {
    register,
    formState: { errors },
    watch,
    trigger,
    setValue,
    handleSubmit,
  } = useForm<WorkTemplateData>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      employment_type: privateCustomer?.living_details?.employment_type,
      employment_since: privateCustomer?.living_details?.employment_since,

      marital_status: privateCustomer?.living_details?.marital_status,
      number_of_children: privateCustomer?.living_details?.number_of_children,
      residence_type: privateCustomer?.living_details?.residence_type,
      residence_since: privateCustomer?.living_details?.residence_since,
    },
  });

  // OnSubmit
  const submitData = (data: WorkTemplateData) => {
    onSubmit({
      employment_type: data.employment_type,
      employment_since: data.employment_since,
      marital_status: data.marital_status,
      number_of_children: data.number_of_children,
      residence_type: data.residence_type,
      residence_since: data.residence_since,
    });
  };

  return (
    <form onSubmit={handleSubmit(submitData)}>
      <FormLayout sectionTitle='Beschäftigung'>
        <SelectBox
          label='Beschäftigt als?'
          initialValue={watch('employment_type')}
          setValueCallBack={(value) =>
            setValue('employment_type', value, { shouldValidate: true })
          }
          {...register('employment_type')}
          className='b2c'
          placeholder='Bitte auswählen...'
          options={Object.values(WorkFamilyHousingDropdown.employment_type)}
          hasError={!!errors?.employment_type?.message}
          errorMessage={errors?.employment_type?.message}
          dataTestId='select_employment_type'
        />
        <InputDate
          label='Seit wann?'
          trigger={trigger}
          {...register('employment_since')}
          setValue={setValue}
          placeholder='TT.MM.JJJJ'
          error={errors.employment_since?.message}
        />
      </FormLayout>

      <FormLayout sectionTitle='Familie & Kinder'>
        <SelectBox
          label='Familienstand'
          initialValue={watch('marital_status')}
          setValueCallBack={(value) =>
            setValue('marital_status', value, { shouldValidate: true })
          }
          {...register('marital_status')}
          className='b2c'
          placeholder='Bitte auswählen...'
          options={Object.values(WorkFamilyHousingDropdown.marital_status)}
          hasError={!!errors?.marital_status?.message}
          errorMessage={errors?.marital_status?.message}
          dataTestId='select_marital_status'
        />

        <SelectBox
          label='Unterhaltspflichtige Kinder?'
          initialValue={watch('number_of_children')}
          setValueCallBack={(value) =>
            setValue('number_of_children', value, { shouldValidate: true })
          }
          {...register('number_of_children')}
          className='b2c'
          options={Object.values(WorkFamilyHousingDropdown.number_of_children)}
          placeholder='Bitte auswählen...'
          hasError={!!errors?.number_of_children?.message}
          errorMessage={errors?.number_of_children?.message}
          dataTestId='select_number_of_children'
        />
      </FormLayout>

      <FormLayout sectionTitle='Wohnsituation'>
        <SelectBox
          label={getText('where_living')}
          initialValue={watch('residence_type')}
          setValueCallBack={(value) =>
            setValue('residence_type', value, { shouldValidate: true })
          }
          {...register('residence_type')}
          className='b2c'
          placeholder='Bitte auswählen...'
          options={Object.values(WorkFamilyHousingDropdown.residence_type)}
          hasError={!!errors?.residence_type?.message}
          errorMessage={errors?.residence_type?.message}
          dataTestId='select_residence_type'
        />

        <InputDate
          label='Seit wann?'
          placeholder='TT.MM.JJJJ'
          trigger={trigger}
          {...register('residence_since')}
          setValue={setValue}
          error={errors.residence_since?.message}
        />
        {tabButton ? (
          <>
            {device === EDevice.MOBILE ? (
              <ButtonGroup
                className='back-next medium'
                type='back-next'
                data-testid='disabled-forward-button'
                appearOnFront
                buttonOneProps={{
                  type: 'button',
                  name: 'form-submit',
                  dataTestId: 'next',
                  children: 'nächster Schritt',
                  loading,
                  onClick: handleSubmit(submitData),
                }}
              />
            ) : (
              <div className='add-additional-person'>
                <Button
                  theme='secondary'
                  type='button'
                  className='save-button next button-text save-and-next-tab'
                  role='presentation'
                  loading={loading}
                  onClick={handleSubmit(submitData)}
                >
                  nächster Schritt
                </Button>
              </div>
            )}
          </>
        ) : (
          <ButtonGroup
            type='back-next'
            className='back-next'
            buttonOneProps={{
              type: 'submit',
              name: 'form-submit',
              loading,
              dataTestId: 'start-fetching',
              children: 'weiter',
            }}
          />
        )}
      </FormLayout>
    </form>
  );
};

export default WorkTemplate;
