import React from 'react';
import { ReactComponent as ArrowBackward } from '../icons/backward-arrow.svg';
import { ReactComponent as ArrowForward } from '../icons/forward-arrow.svg';
import Spinner from '../spinner/spinner';
import './button.css';

export interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<any>, any> {
  theme?:
    | 'primary'
    | 'call-to-action'
    | 'secondary'
    | 'clear'
    | 'choice'
    | 'danger';
  arrow?: 'left' | 'right';
  className?: string;
  micro?: boolean;
  disabled?: boolean;
  loading?: boolean;
  type: 'button' | 'submit';
}

type ButtonRef = HTMLButtonElement;

const Button = React.forwardRef<ButtonRef, ButtonProps>(
  (
    {
      theme = 'primary',
      micro = false,
      arrow,
      className = '',
      type = 'button',
      children,
      disabled,
      loading,
      ...props
    }: ButtonProps,
    ref,
  ) => {
    return (
      <button
        {...props}
        type={type === 'submit' ? 'submit' : 'button'}
        ref={ref}
        disabled={loading || disabled}
        className={`button button--${theme} ${micro ? 'button--micro' : ''}  
        ${arrow ? 'button--arrow' : ''}  
        ${arrow === 'left' ? 'button--previous' : ''}  
        ${arrow === 'right' ? 'button--next' : ''} ${className} button-text`}
      >
        {!arrow && (loading ? <Spinner className='tab-spinner' /> : children)}

        {arrow &&
          (arrow === 'left' ? (
            <ArrowBackward
              className='icon backward-arrow'
              name='backward-arrow'
            />
          ) : (
            <ArrowForward className='icon forward-arrow' name='forward-arrow' />
          ))}
      </button>
    );
  },
);

export default Button;
