import React, { useState } from 'react';
import { getPartnerText } from '../../../../utils/getter';
import Button from '../../../b2b/components/button/button';
import Modal from '../../../b2b/components/modal/modal';

interface ConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  confirmationText: string;
  cancel: () => void;
  success: () => void;
}

const ConfirmationModal: React.FC<ConfirmationProps> = ({
  isOpen,
  onClose,
  confirmationText,
  cancel,
  success,
}) => {
  const [isEnabled, setIsEnabled] = useState<boolean>(true);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      type='middle'
      withExitBtn
      direction='down'
    >
      <h1 className='heading'>{confirmationText}</h1>
      <Button
        theme='danger'
        type='button'
        className='back-next revert margin-auto'
        disabled={!isEnabled}
        onClick={() => {
          setIsEnabled(false);
          success();
        }}
      >
        {getPartnerText('remove_contract')}
      </Button>
    </Modal>
  );
};

export default ConfirmationModal;
