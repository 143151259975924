import { SequenceConfig } from '../redux/sequence/types';
import store from '../redux/store';
import { routes } from '../routes';
import { Type } from '../types/instant-lease-api';
import { getFinalPageText, getPartnerText, getText } from '../utils/getter';
import sequenceConditions from './sequence-conditions';

const getFlexB2CSequence = (): SequenceConfig[] => {
  // Get Final Page Text
  const finalPageText = getFinalPageText(
    store.getState().leaseApp?.activeApplication,
  );

  return sequenceConditions([
    {
      key: 'selbstauskunft',
      name: 'Selbstauskunft',
      title: 'Selbstauskunft',
      subTitle:
        'Zunächst fragen wir Sie nach ein paar Angaben über sich. Dabei bitten wir Sie auch um Nachweise zu ihrem Einkommen, zum Beispiel eine Gehaltsbescheinigung.',
      steps: [
        {
          key: 'personalInformation',
          name: 'Persönliche Daten',
          route: routes.leaseApplication.self_disclosure.personalInformation,
        },
        {
          key: 'contact',
          name: 'Kontakt',
          route: routes.leaseApplication.self_disclosure.contact,
        },
        {
          key: 'workFamilyHousing',
          name: 'Familie ',
          route: routes.leaseApplication.self_disclosure.workFamilyHousing,
        },
        {
          key: 'incomeAndExpenses',
          name: 'Einnahmen & Ausgaben ',
          route: routes.leaseApplication.self_disclosure.incomeAndExpenses,
        },
        {
          key: 'bank',
          name: 'Bankkonto',
          route: routes.leaseApplication.self_disclosure.bank,
        },
        {
          key: 'uploadDocuments',
          name: 'Dokumente ',
          route: routes.leaseApplication.self_disclosure.uploadDocuments,
        },
        {
          key: 'dataSummary',
          name: 'Zusammenfassung',
          route: routes.leaseApplication.self_disclosure.dataSummary,
        },
      ],
    },
    {
      key: 'flexFinal',
      name: 'Zum Abschluss',
      title: `Wie geht es weiter`,
      subTitle: '',
      steps: [
        {
          key: 'flexFinal',
          name: 'Zum Abschluss',
          route: routes.leaseApplication.flex.flexFinal,
        },
      ],
    },
    {
      key: 'success',
      name: 'Success Message',
      title: finalPageText,
      subTitle: '',
      steps: [],
    },
  ]);
};

const getFlexB2BSequence = (): SequenceConfig[] => {
  const { customer } = store.getState().leaseApp?.activeApplication || {};

  // Get Final Page Text
  const finalPageText = getFinalPageText(
    store.getState().leaseApp?.activeApplication,
  );

  if (customer && 'individual' in customer) {
    return sequenceConditions([
      {
        key: 'company',
        name: getText('phase_title_1'),
        title: getPartnerText(`welcome_headline`),
        subTitle: getText('phase1start_subtitle'),
        steps: [
          {
            key: 'companyInfo',
            name: 'Informationen über die Firma',
            route: routes.leaseApplication.companyProfile.companyInfo,
          },
          {
            key: 'companySearchResult',
            name: 'Unternehmen suchen',
            route: routes.leaseApplication.companyProfile.companySearchResult,
          },
          {
            key: 'companyAddress',
            name: 'Adresse des Unternehmens',
            route: routes.leaseApplication.companyProfile.companyAddress,
          },
          {
            key: 'companyContacts',
            name: 'Kontakte zum Unternehmen',
            route: routes.leaseApplication.companyProfile.companyContacts,
          },
          {
            key: 'companyData',
            name: 'Daten zum Unternehmen',
            route: routes.leaseApplication.companyProfile.companyData,
          },
          {
            key: 'confirmCompany',
            name: 'Zusammenfassung',
            route: routes.leaseApplication.companyProfile.confirm,
          },
        ],
      },
      {
        key: 'kyc',
        name: 'Unternehmensvertreter und Nachweise',
        title: 'Unternehmensvertreter und Nachweise',
        subTitle: getText('phase2start_subtitle'),
        steps: [
          {
            key: 'ownerData',
            name: 'Unterzeichnerdaten',
            route: routes.leaseApplication.kyc.ownerData,
          },
          {
            key: 'documentUpload',
            name: 'Dokument hochladen',
            route: routes.leaseApplication.kyc.documentUpload,
          },
          {
            key: 'confirmKyc',
            name: 'Zusammenfassung',
            route: routes.leaseApplication.kyc.confirm,
          },
        ],
      },
      {
        key: 'flexFinal',
        name: 'Vertragsabschluss',
        title: 'Weiter zur Video-Identifikation und zur digitalen Unterschrift',
        subTitle: '',
        steps: [
          {
            key: 'flexFinal',
            name: 'Zum Abschluss',
            route: routes.leaseApplication.flex.flexFinal,
          },
        ],
      },
      {
        key: 'success',
        name: 'Success Message',
        title: finalPageText,
        subTitle: '',
        steps: [],
      },
    ]);
  }

  return sequenceConditions([
    {
      key: 'company',
      name: getText('phase_title_1'),
      title: getPartnerText(`welcome_headline`),
      subTitle: getText('phase1start_subtitle'),
      steps: [
        {
          key: 'companyInfo',
          name: 'Company Information',
          route: routes.leaseApplication.companyProfile.companyInfo,
        },
        {
          key: 'companySearchResult',
          name: 'Company Search Result',
          route: routes.leaseApplication.companyProfile.companySearchResult,
        },
        {
          key: 'companyAddress',
          name: 'Company Address',
          route: routes.leaseApplication.companyProfile.companyAddress,
        },
        {
          key: 'companyContacts',
          name: 'Company Contacts',
          route: routes.leaseApplication.companyProfile.companyContacts,
        },
        {
          key: 'companyData',
          name: 'Company Data',
          route: routes.leaseApplication.companyProfile.companyData,
        },
        {
          key: 'confirmCompany',
          name: 'Zusammenfassung',
          route: routes.leaseApplication.companyProfile.confirm,
        },
      ],
    },
    {
      key: 'kyc',
      name: 'Unternehmensvertreter',
      title: 'Unternehmensvertreter und Nachweise',
      subTitle: getText('phase2start_subtitle'),
      steps: [
        {
          key: 'beneficialOwners',
          name: 'Wirtschaftlich Berechtigte',
          route: routes.leaseApplication.kyc.beneficialOwners,
        },
        {
          key: 'authorizedRepresentatives',
          name: 'Vertretungsberechtigte',
          route: routes.leaseApplication.kyc.authorizedRepresentatives,
        },
        {
          key: 'ownerData',
          name: 'Daten der Unterzeichner',
          route: routes.leaseApplication.kyc.ownerData,
        },
        {
          key: 'transparenzregister',
          name: 'Transparenzregister',
          route: routes.leaseApplication.kyc.transparencyRegister,
        },
        {
          key: 'documentUpload',
          name: 'Dokument hochladen',
          route: routes.leaseApplication.kyc.documentUpload,
        },
        {
          key: 'confirmKyc',
          name: 'Zusammenfassung',
          route: routes.leaseApplication.kyc.confirm,
        },
      ],
    },
    {
      key: 'flexFinal',
      name: 'Vertragsabschluss',
      title: 'Weiter zur Video-Identifikation und zur digitalen Unterschrift',
      subTitle: '',
      steps: [
        {
          key: 'flexFinal',
          name: 'Zum Abschluss',
          route: routes.leaseApplication.flex.flexFinal,
        },
      ],
    },
    {
      key: 'success',
      name: 'Success Message',
      title: finalPageText,
      subTitle: '',
      steps: [],
    },
    {
      key: 'something wrong',
      name: 'Wrong Message',
      title: 'Entschuldigung, da ist etwas schief gelaufen',
      subTitle: '',
      steps: [],
    },
  ]);
};

const getFlexSequence = (status: string, type?: Type): SequenceConfig[] => {
  if (type === Type.B2B) {
    return getFlexB2BSequence();
  }
  return getFlexB2CSequence();
};

export default getFlexSequence;
