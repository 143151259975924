import { SequenceConfig } from '../redux/sequence/types';
import store from '../redux/store';
import { routes } from '../routes';
import { finalPageText } from '../utils/getter';
import { hasPostident } from '../utils/helper';
import sequenceConditions from './sequence-conditions';

const PrivateSequence = (): SequenceConfig[] => {
  const activeLeaseApplication = store.getState().leaseApp?.activeApplication;
  return sequenceConditions([
    {
      key: 'Selbstauskunft',
      name: 'Selbstauskunft',
      title: 'Erstelle jetzt deinen Antrag',
      subTitle:
        'Bitte trage deine Daten in das Online-Formular ein. Halte bitte als Einkommensnachweis deine letzten drei Gehaltsnachweise bereit.',
      steps: [
        {
          key: 'personalInformation',
          name: 'Persönliche Daten',
          route: routes.leaseApplication.self_disclosure.personalInformation,
        },
        {
          key: 'contact',
          name: 'Kontakt',
          route: routes.leaseApplication.self_disclosure.contact,
        },
        {
          key: 'workFamilyHousing',
          name: 'Familie ',
          route: routes.leaseApplication.self_disclosure.workFamilyHousing,
        },
        {
          key: 'incomeAndExpenses',
          name: 'Einnahmen & Ausgaben ',
          route: routes.leaseApplication.self_disclosure.incomeAndExpenses,
        },
        {
          key: 'bank',
          name: 'Bankkonto',
          route: routes.leaseApplication.self_disclosure.bank,
        },
        {
          key: 'uploadDocuments',
          name: 'Dokumente ',
          route: routes.leaseApplication.self_disclosure.uploadDocuments,
        },
        {
          key: 'hasCoApplicant',
          name: 'Mitantragssteller',
          route: routes.leaseApplication.self_disclosure.hasCoApplicant,
        },
        {
          key: 'dataSummary',
          name: 'Zusammenfassung',
          route: routes.leaseApplication.self_disclosure.dataSummary,
        },
      ],
    },
    {
      key: 'bonitätsprüfung',
      name: 'Bonitätsprüfung',
      title: 'Bonitätsprüfung',
      subTitle:
        'Um einen Leasingantrag zu bearbeiten, sind wir gesetzlich verpflichtet, eine Bonitätsprüfung durchzuführen. Diese kann bis zu zwei Werktage dauern.',
      steps: [
        {
          key: 'creditCheckB2CStart',
          name: 'Kreditprüfung starten',
          route: routes.leaseApplication.creditCheckB2C.creditCheckB2CStart,
        },
        {
          key: 'creditCheckB2CStatus',
          name: 'Kreditprüfung',
          route: routes.leaseApplication.creditCheckB2C.creditCheckB2CStatus,
        },
      ],
    },
    {
      key: 'identityCheckB2C',
      name: 'Identifikation und Signatur',
      title: 'Identifikation und Signatur',
      subTitle:
        'Identifiziere dich mittels Video-Chat und unterschreibe deinen Antrag digital. Halte dafür bitte ein gültiges Ausweisdokument bereit.',
      steps: hasPostident(activeLeaseApplication),
    },
    {
      key: 'success',
      name: 'Success Message',
      title: finalPageText(activeLeaseApplication),
      subTitle:
        'Du hast den Vertrag erfolgreich unterzeichnet und kannst ihn jetzt herunterladen.',
      steps: [],
    },
    {
      key: 'something wrong',
      name: 'Wrong Message',
      title: 'Entschuldigung, da ist etwas schief gelaufen',
      subTitle: '',
      steps: [],
    },
  ]);
};

export default PrivateSequence;
