import { yupResolver } from '@hookform/resolvers/yup';
import CountryList from 'country-list-with-dial-code-and-flag';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { EDevice } from '../../../../redux/sequence/enums';
import { RootState } from '../../../../redux/types';
import {
  LegalConditionsMarketing,
  PrivateCustomer,
} from '../../../../types/instant-lease-api';
import { formatPhoneNumber } from '../../../../utils/helper';
import Button from '../../../b2b/components/button/button';
import Checkbox from '../../../b2b/components/checkbox/checkbox';
import InternationalPhoneInput from '../../../b2b/components/international-phone-input/international-phone-input';
import ButtonGroup from '../../../b2b/molecules/button-group/button-group';
import FormLayout from '../../../common/form-layout/form-layout';
import InputField from '../../../common/input-field/input-field';
import SlideCheckbox from '../../../common/slide-checkbox/slide-checkbox';

interface ContactDataProps {
  onSubmit: (owner: ContactTemplateData) => void;
  tabButton: boolean;
  privateCustomer: PrivateCustomer | undefined;
  loading?: boolean;
  marketingAgreements?: LegalConditionsMarketing;
  singer?: boolean;
}

export interface ContactTemplateData {
  email: string;
  phone_number: string;
  terms: NonNullable<boolean | undefined>;
  marketing_email?: boolean | undefined;
  marketing_phone?: boolean | undefined;
}

const ContactTemplate: React.FC<ContactDataProps> = ({
  privateCustomer,
  tabButton,
  onSubmit,
  loading,
  marketingAgreements,
  singer,
}) => {
  // Auth Storage
  const { email } = useSelector((state: RootState) => state.auth);

  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Sequence Storage
  const { device } = useSelector((state: RootState) => state.sequence);

  // check whether is it posbank loyalty prometion
  const isPostBank =
    activeApplication?.loyalty_card?.loyalty_card_type?.toLowerCase() ===
    'postbank';

  // validationSchema
  const validationSchema = yup.object().shape({
    phone_number: yup
      .string()
      .matches(
        /^[0-9][0-9]+$/gm,
        'Bitte geben Sie eine gültige Telefonnummer an (z.B.) 123456789',
      )
      .required('Pflichtfeld'),
    terms: yup
      .boolean()
      .required('Bitte akzeptiere die Bedingungen um fortzufahren')
      .oneOf([true], 'Bitte akzeptiere die Bedingungen um fortzufahren'),
    email: yup
      .string()
      .email('Bitte vollständige Email Adresse eintragen')
      .required('Pflichtfeld'),
    marketing_email: yup.boolean().optional(),
    marketing_phone: yup.boolean().optional(),
  });

  const contacts = privateCustomer?.person?.contact_details;

  // Set country code when user pick flag
  const [countryCode, setCountry] = useState<string>('+49');

  const getEmail = () => {
    if (contacts?.emails && contacts?.emails?.length > 0) {
      return contacts?.emails[0].value;
    } else if (!singer) {
      return email;
    }
  };

  // Form hook
  const {
    register,
    trigger,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm<ContactTemplateData>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      phone_number:
        (contacts?.phone_numbers &&
          contacts?.phone_numbers.length &&
          contacts?.phone_numbers[0].value) ||
        '',
      email: getEmail(),
      terms:
        (contacts?.phone_numbers &&
          contacts?.phone_numbers.length &&
          contacts?.phone_numbers[0].data_usage?.answer) ||
        false,
      marketing_phone: marketingAgreements?.phone_consent_agreed?.answer,
      marketing_email: marketingAgreements?.email_consent_agreed?.answer,
    },
  });

  useEffect(() => {
    if (!_.isEmpty(contacts) && contacts?.phone_numbers?.length) {
      let count = 0;
      if (contacts?.phone_numbers[0]?.value) {
        let matchedCountires = [];
        for (let i = 0; i <= 5; i++) {
          matchedCountires = CountryList.findByDialCode(
            contacts?.phone_numbers[0]?.value?.slice(0, i),
          );

          if (matchedCountires.length === 1) {
            count = i;
          }
        }
      }

      // Remove intl code from number
      const phoneNumberWithoutIntlCode = contacts?.phone_numbers[0]?.value
        ?.substring(count)
        .replace(/\s+/g, '');

      setCountry(contacts?.phone_numbers[0]?.value?.slice(0, count) || '+49');

      setValue('phone_number', phoneNumberWithoutIntlCode || '', {
        shouldValidate: phoneNumberWithoutIntlCode === '',
      });
      trigger();
    }
  }, [contacts, setValue, trigger]);

  // Phone number value
  formatPhoneNumber(getValues('phone_number'), setValue, trigger);

  // Checkbox Terms visibility control
  const [isTermsVisible, setIsTermsVisible] = useState<boolean>(
    device === EDevice.MOBILE ? true : false,
  );

  // Submit Data
  const submitData = (data: ContactTemplateData) => {
    onSubmit({
      phone_number: `${countryCode}${data.phone_number}`,
      email: data.email,
      terms: data.terms,
      marketing_email: data.marketing_email,
      marketing_phone: data.marketing_phone,
    });
  };

  return (
    <form onSubmit={handleSubmit(submitData)}>
      <FormLayout sectionTitle='Kontaktdaten'>
        <InputField
          label='E-Mail-Adresse'
          {...register('email')}
          error={errors.email?.message}
        />

        {!isPostBank && (
          <>
            <div className='input-box'>
              <div className='input-label'></div>
              <p className='input-inner-container small-text dark-grey'>
                Ich willige ein, dass die ALD AutoLeasing D GmbH mich zwecks
                Information über Produkte (Leasingangebote, allgemeine oder
                vertragsbezogene Werbung) und Dienstleistungen (z.B.
                Vertragsverlängerungen, zusätzliche Dienste), Einladung zu
                Events/Panels und zur Markt- und Meinungsforschung kontaktiert,
                und zwar per:
              </p>
            </div>

            <div className='input-margin'>
              <div></div>
              <Checkbox
                square
                checkMarkType='check'
                checkboxPosition='top'
                type='checkbox'
                {...register('marketing_email')}
                errorMessage={errors.marketing_email?.message}
              >
                <span className='small-text dark-grey'>
                  (optional) E-Mail (Name, Vorname, E-Mail-Adresse)
                </span>
              </Checkbox>
            </div>
            <div className='input-margin'>
              <div></div>
              <Checkbox
                square
                checkMarkType='check'
                checkboxPosition='top'
                type='checkbox'
                {...register('marketing_phone')}
                errorMessage={errors.marketing_phone?.message}
              >
                <span className='small-text dark-grey'>
                  (optional) Telefon, SMS, Messenger (Name, Vorname,
                  Telefonnummer)
                </span>
              </Checkbox>
            </div>

            <div className='input-box'>
              <div className='input-label'></div>
              <p className='input-inner-container small-text dark-grey'>
                Meine Einwilligung umfasst auch eine Analyse von Öffnungs- und
                Klickraten sowie deren Speicherung und Auswertung in
                Empfängerprofilen zu Zwecken der nutzeroptimierten Gestaltung
                künftiger Mitteilungen. Hinweis: Meine Einwilligung kann ich
                jederzeit mit Wirkung für die Zukunft ganz oder teilweise
                widerrufen. Zum Beispiel per E-Mail an{' '}
                <a
                  href='mailto:datenschutz-widerruf@aldautomotive.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  datenschutz-widerruf@aldautomotive.com
                </a>{' '}
              </p>
            </div>
          </>
        )}

        <InternationalPhoneInput
          label='Mobiltelefonnummer'
          getCode={(value) => setCountry(value)}
          onFocus={() => setIsTermsVisible(true)}
          code={countryCode}
          placeholder='123456789'
          {...register('phone_number')}
          onBlur={() => setIsTermsVisible(false)}
          error={errors?.phone_number?.message}
        />

        <SlideCheckbox
          square
          isCheckBoxVisible={isTermsVisible}
          checkboxValue={getValues('terms')}
          checkMarkType='check'
          checkboxPosition='top'
          type='checkbox'
          {...register('terms')}
          errorMessage={errors.terms?.message}
        >
          <span className='baseline-normal gender-option'>
            <p className='small-text dark-grey'>
              Ich stimme zu, dass meine Telefonnummer und E-Mail-Adresse durch
              die ALD AutoLeasing D GmbH zum Zwecke der schnellen und
              reibungslosen Bestellung für 90 Tage gespeichert werden darf. Ich
              bin einverstanden, dass mich die ALD zum Zwecke des
              Bestellabschlusses kontaktieren darf, wenn ich den Bestellvorgang
              nicht vollständig durchgeführt habe. Hinweis: Du kannst diese
              Einwilligung jederzeit mit Wirkung für die Zukunft per E-Mail an{' '}
              <a
                href='mailto:datenschutz-widerruf@aldautomotive.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                datenschutz-widerruf@aldautomotive.com
              </a>{' '}
              widerrufen.
            </p>
          </span>
        </SlideCheckbox>
        {tabButton ? (
          <>
            {device === EDevice.MOBILE ? (
              <ButtonGroup
                className='back-next medium'
                type='back-next'
                data-testid='disabled-forward-button'
                appearOnFront
                buttonOneProps={{
                  type: 'button',
                  name: 'form-submit',
                  dataTestId: 'next',
                  children: 'nächster Schritt',
                  loading,
                  onClick: handleSubmit(submitData),
                }}
              />
            ) : (
              <div className='add-additional-person'>
                <Button
                  theme='secondary'
                  type='button'
                  className='save-button next button-text save-and-next-tab'
                  role='presentation'
                  loading={loading}
                  onClick={handleSubmit(submitData)}
                >
                  nächster Schritt
                </Button>
              </div>
            )}
          </>
        ) : (
          <ButtonGroup
            type='back-next'
            className='back-next'
            buttonOneProps={{
              type: 'submit',
              name: 'form-submit',
              loading,
              dataTestId: 'start-fetching',
              children: 'weiter',
            }}
          />
        )}
      </FormLayout>
    </form>
  );
};

export default ContactTemplate;
