import React from 'react';
import { ReactComponent as Help } from '../../b2b/components/icons/help.svg';
import './two-input-fields.css';

interface TwoInputFieldsProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
  gridTemplateColumns?: string;
  tooltip?: string;
}
const TwoInputFields: React.FC<TwoInputFieldsProps> = ({
  children,
  label,
  tooltip,
  gridTemplateColumns,
}) => {
  return (
    <div className={`two-fields ${label ? 'with-label' : 'without-label'}`}>
      {label && (
        <label
          className='content-normal two-field-label'
          style={{ display: 'flex' }}
        >
          {label}{' '}
          {tooltip && (
            <div className='hover-text'>
              <Help style={{ marginLeft: '5px' }} />
              <p className='tooltip-text'>{tooltip}</p>
            </div>
          )}
        </label>
      )}
      <div
        style={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns,
          gridColumnGap: '4.2%',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default TwoInputFields;
