import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout';
import { setNotification } from '../../../../redux/cache-slice';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import { APIPaths } from '../../../../types/instant-lease-api';
import { getText } from '../../../../utils/getter';
import { errorLogging } from '../../../../utils/logging';
import Navigation from '../../../../utils/navigation';
import RadioContainer from '../../../b2b/components/radio-container/radio-container';
import Radio from '../../../b2b/components/radio/radio';
import ButtonGroup from '../../../b2b/molecules/button-group/button-group';
import CoApplicant from '../co-applicant/co-applicant';

const HasCoApplicant: React.FC = () => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // Navigation hook
  const navigate = useNavigate();

  // Navigation Class
  const { nextRoute } = new Navigation();

  // Dispatch Hook
  const dispatch = useAppDispatch();

  const coApplicantCondition = (): boolean | undefined => {
    if (
      activeApplication?.customer?.private?.signers &&
      activeApplication?.customer?.private?.signers.length > 0
    ) {
      return activeApplication?.customer?.private?.signers.length > 1;
    }
    return undefined;
  };

  // Checkbox value
  const [hasCoApplicant, setHasCoApplicant] = useState<boolean | undefined>(
    coApplicantCondition,
  );

  // Submit in case of no co-applicant
  const handleSubmit = async () => {
    navigate(nextRoute());
  };

  // Handle checkbox changes
  const handleCheckbox = (selection: boolean) => () => {
    setHasCoApplicant(selection);
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          is_co_applicant_present: selection,
        },
        path: APIPaths.UPDATE_MAIN_SIGNER,
      }),
    ).catch((e) => {
      errorLogging(e);
      dispatch(
        setNotification({
          notificationVisibility: true,
          notificationHasError: true,
          notificationTitle: 'Das tut uns leid.',
          notificationBody: getText('error_unable_to_process_data'),
        }),
      );
    });
  };

  return (
    <Layout
      heading='Möchtest du einen Mitantragsteller hinzuzufügen?'
      stepStatus
      subHeading={getText('has_partner_subTitle')}
      className='choice-modal'
    >
      <div
        className='template-body '
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <p className='content-bold' style={{ margin: '24px 0 16px 0' }}>
          Jetzt Mitantragssteller hinzufügen:
        </p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '24px',
          }}
          className='template-body '
        >
          <RadioContainer>
            <Radio
              checked={hasCoApplicant === true}
              onChange={handleCheckbox(true)}
            >
              <p className='content-normal'>Ja</p>
            </Radio>
            <Radio
              checked={hasCoApplicant === false}
              onChange={handleCheckbox(false)}
            >
              <p className='content-normal'>Nein</p>
            </Radio>
          </RadioContainer>
        </div>
        {hasCoApplicant && <CoApplicant />}
      </div>

      {!hasCoApplicant && (
        <ButtonGroup
          className='back-next medium'
          type='back-next'
          data-testid='disabled-forward-button'
          buttonOneProps={{
            type: 'button',
            disabled: hasCoApplicant === undefined,
            onClick: handleSubmit,
            name: 'form-submit',
            dataTestId: 'next',
            children: 'weiter',
          }}
        />
      )}
    </Layout>
  );
};

export default HasCoApplicant;
