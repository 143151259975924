import ls from 'localstorage-slim';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../layout';
import Navigation from '../../../../utils/navigation';
import RadioContainer from '../../components/radio-container/radio-container';
import Radio from '../../components/radio/radio';
import ButtonGroup from '../../molecules/button-group/button-group';

interface ChoiceModalProps {
  title: string;
  extraInfo?: string;
}

const HasBeneficialOwners: React.FC<ChoiceModalProps> = ({
  title,
  extraInfo,
}) => {
  // Histroy Hook
  const navigate = useNavigate();

  // Navigation
  const { nextRoute } = new Navigation();

  // Get local storage value
  const getLocalBo = ls.get('bo');

  // Set checkbox value
  const [checkBoxValue, setCheckBoxValue] = useState<boolean | undefined>(
    getLocalBo === 'true' || true,
  );

  /**
   * saves value in local storage as (bo)
   * if the user select yes goes to beneficial owner page
   * is no removes BO from sequence(check sequence-conditions.ts) goes to auth rep page
   */
  const handleSubmit = () => {
    if (checkBoxValue) {
      ls.set('bo', 'true');
    } else {
      ls.set('bo', 'false');
    }

    navigate(nextRoute());
  };
  return (
    <Layout
      heading={title}
      stepStatus
      subHeading={extraInfo}
      className='choice-modal'
    >
      <div className='template-body'>
        <p className='content-normal dark-grey'>
          Gibt es wirtschaftlich berechtigte Personen in deinem Unternehmen?
        </p>
        <div className='top-48'>
          <RadioContainer>
            <Radio
              checked={checkBoxValue === true}
              onChange={() => setCheckBoxValue(true)}
            >
              <p className='content-normal'>Ja</p>
            </Radio>
            <Radio
              checked={checkBoxValue === false}
              onChange={() => setCheckBoxValue(false)}
            >
              <p className='content-normal'>Nein</p>
            </Radio>
          </RadioContainer>
        </div>
      </div>

      <ButtonGroup
        className='back-next medium'
        type='back-next'
        data-testid='disabled-forward-button'
        buttonOneProps={{
          type: 'button',
          onClick: handleSubmit,
          name: 'form-submit',
          dataTestId: 'next',
          children: 'weiter',
        }}
      />
    </Layout>
  );
};

export default HasBeneficialOwners;
