import { yupResolver } from '@hookform/resolvers/yup';
import ls from 'localstorage-slim';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import useGoogleMapsApi from '../../../../hooks/useGoogleMapsApi';
import Layout from '../../../../layout';
import countries from '../../../../localization/countries.json';
import { updateCustomerData } from '../../../../redux/leaseApp/lease-app-slice';
import { useAppDispatch } from '../../../../redux/store';
import { RootState } from '../../../../redux/types';
import {
  APIPaths,
  Company,
  Individual,
  LeaseApplication,
} from '../../../../types/instant-lease-api';
import legalForms from '../../../../types/legal-forms';
import { getCountryByCode, getText } from '../../../../utils/getter';
import Navigation from '../../../../utils/navigation';
import FormLayout from '../../../common/form-layout/form-layout';
import InputField from '../../../common/input-field/input-field';
import SelectBox from '../../../common/select-box/select-box';
import TwoInputFields from '../../../common/two-input-fields/two-input-fields';
import Button from '../../components/button/button';
import ByGoogle from '../../components/icons/by-google.png';
import Modal from '../../components/modal/modal';
import ButtonGroup from '../../molecules/button-group/button-group';

interface CompanyAddressProps {
  getCustomer: (
    leaseApp: LeaseApplication | null,
  ) => Company | Individual | undefined;
  getLegalform?: (leaseApp: LeaseApplication | null) => string | undefined;
}

const CompanyAddress: React.FC<CompanyAddressProps> = ({
  getCustomer,
  getLegalform,
}) => {
  // Lease Application Storage
  const { activeApplication } = useSelector(
    (state: RootState) => state.leaseApp,
  );

  // sets info modal visibility if the user selects certani type of legal form (LEGAL_FORM_GEWERBEBETRIEB)
  const [infoVisibility, setInfoVisibility] = useState<boolean>(false);

  // Auth Storage
  const { accessToken } = useSelector((state: RootState) => state.auth);

  // legal form not machted modal
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  useEffect(() => {
    if (
      ls.get('selected_legal_form') &&
      ls.get('selected_legal_form') !==
        (activeApplication?.customer?.company?.legal_form ||
          activeApplication?.customer?.individual?.legal_form)
    ) {
      setModalVisibility(true);
    }
    ls.remove('selected_legal_form');
  }, [activeApplication]);

  // Get customer
  const customer = getCustomer(activeApplication);

  // Navigation
  const { nextRoute } = new Navigation();

  // Dispatch hook
  const dispatch = useAppDispatch();

  // State company list result from api
  const [submitting, setSubmitting] = useState<boolean>(false);

  // History Hook
  const navigate = useNavigate();

  // Validation schema
  const validationSchema = yup.object().shape({
    legalForm: !customer?.legal_form
      ? yup.string().required('Pflichtfeld')
      : yup.string(),
    companyName: yup.string().required('Pflichtfeld').max(255),
    street: yup.string().required('Pflichtfeld'),
    additionalAddress: yup.string(),
    postalCode: yup.string().required('Pflichtfeld'),
    city: yup.string().required('Pflichtfeld'),
    country: yup.string().required('Pflichtfeld'),
  });

  const name: string = customer?.name ?? ls.get('companyName') ?? '';

  const additionalLines =
    customer?.contact_details?.addresses &&
    customer?.contact_details?.addresses.length &&
    customer?.contact_details?.addresses[0].additional_lines;

  const additionalLinesAddress = additionalLines
    ? additionalLines.length > 0
      ? additionalLines[0]
      : ''
    : '';

  const {
    register,
    formState: { errors },
    watch,
    getValues,
    setValue,
    setError,
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      legalForm: getLegalform && getLegalform(activeApplication),
      companyName: name,
      street:
        (customer?.contact_details?.addresses &&
          customer?.contact_details?.addresses.length &&
          customer?.contact_details?.addresses[0].street_line) ||
        '',
      additionalAddress: additionalLinesAddress,
      postalCode:
        (customer?.contact_details?.addresses &&
          customer?.contact_details?.addresses.length &&
          customer?.contact_details?.addresses[0].postal_code) ||
        '',
      city:
        (customer?.contact_details?.addresses &&
          customer?.contact_details?.addresses.length &&
          customer?.contact_details?.addresses[0].locality) ||
        '',
      country:
        (customer?.contact_details?.addresses &&
          customer?.contact_details?.addresses.length &&
          getCountryByCode(customer?.contact_details?.addresses[0].country_code)
            ?.value) ||
        'DEU',
    },
  });

  const {
    predictionsResult,
    handleAddressSelection,
    handleOnChange,
    highlightStreet,
    restOfStreet,
    restOfTheAddress,
  } = useGoogleMapsApi({
    setValue,
    setError,
    getValues,
    googleMapsAPIUrl: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    displayLanguage: 'de',
  });

  const submit = ({
    companyName,
    street,
    additionalAddress,
    postalCode,
    city,
  }: {
    companyName: string;
    street: string;
    additionalAddress?: yup.Maybe<string | undefined>;
    postalCode: string;
    city: string;
    country: string;
  }) => {
    const dispatchArray: Promise<any>[] = [];
    setSubmitting(true);
    const inputs: {
      label: string;
      value: Record<string, unknown> | string | number | null;
      path: APIPaths;
    }[] = [
      {
        label: 'company_name',
        value: companyName,
        path: APIPaths.COMPANY_NAME,
      },
      {
        label: 'address',
        value: {
          street_line: street,
          additional_lines: [additionalAddress],
          postal_code: postalCode,
          locality: city,
          country_code: 'DEU',
        },
        path: APIPaths.ADDRESS,
      },
    ];

    inputs.forEach((input) =>
      dispatchArray.push(
        dispatch(
          updateCustomerData({
            accessToken,
            leaseApplicationId: activeApplication?.uuid || '',
            inputValue: {
              [input.label]: input.value,
            },
            path: input.path,
          }),
        ),
      ),
    );

    Promise.all(dispatchArray).then(() => {
      setSubmitting(false);
      navigate(nextRoute());
    });
  };

  // Selected value
  const setSelectedKey = (key: any) => {
    setValue('legalForm', key, { shouldValidate: true });
    if (
      [
        'LEGAL_FORM_GEWERBEBETRIEB',
        'LEGAL_FORM_EINGETRAGENER_KAUFMANN',
        'LEGAL_FORM_FREIE_BERUFE',
      ].includes(key as string)
    ) {
      setInfoVisibility(true);
    }
    ls.set('selected_legal_form', key);
    dispatch(
      updateCustomerData({
        accessToken,
        leaseApplicationId: activeApplication?.uuid || '',
        inputValue: {
          legal_form: key,
        },
        path: APIPaths.LEGAL_FORM,
      }),
    );
  };

  return (
    <Layout
      heading={getText('company_data_form_headline')}
      stepStatus
      subHeading='Bitte prüfe und vervollständige deine Daten.'
    >
      <FormLayout sectionTitle='Unternehmensadresse'>
        {' '}
        <form onSubmit={handleSubmit(submit)}>
          {!customer?.crefo_id && (
            <SelectBox
              label='Rechtsform'
              setValueCallBack={setSelectedKey}
              initialValue={watch('legalForm')}
              options={Object.values(legalForms)}
              placeholder='Bitte auswählen...'
              hasError={!!errors?.legalForm?.message}
              errorMessage={errors?.legalForm?.message}
              {...register('legalForm')}
            />
          )}

          <InputField
            label='Unternehmensname'
            {...register('companyName')}
            error={errors.companyName?.message}
          />

          <div>
            <InputField
              label='Straße | Hausnummer'
              placeholder='Straße, Hausnummer'
              {...register('street')}
              onChange={(e) => handleOnChange(e.target.value)}
              error={errors.street?.message}
            />
            {predictionsResult.length !== 0 && (
              <div style={{ margin: '20px 0 40px 0' }}>
                <ul data-testid='list' className='autocompleteList'>
                  {predictionsResult.map((prediction) => (
                    <li
                      role='presentation'
                      className='input-text'
                      key={prediction.place_id}
                      onClick={() =>
                        handleAddressSelection(prediction.place_id)
                      }
                    >
                      <span className='matched content-bold'>
                        {highlightStreet(prediction.description)}
                      </span>
                      <span className='rest'>
                        {restOfStreet(prediction.description)}
                      </span>
                      {restOfTheAddress(prediction.description)}
                    </li>
                  ))}
                </ul>
                <img
                  style={{
                    width: '80px',
                    alignSelf: 'flex-end',
                    marginRight: '0',
                  }}
                  src={ByGoogle}
                  alt='powered by google'
                />
              </div>
            )}
            <InputField
              label='Adresszusatz'
              extraLabel=' (optional)'
              {...register('additionalAddress')}
              error={errors.additionalAddress?.message}
            />

            <TwoInputFields
              label='Postleitzahl | Ort'
              gridTemplateColumns='25% 70%'
            >
              <InputField
                {...register('postalCode')}
                error={errors.postalCode?.message}
                withErrorMessage={false}
              />
              <InputField
                {...register('city')}
                error={errors.city?.message}
                withErrorMessage={false}
              />
            </TwoInputFields>
            <SelectBox
              label='Land'
              initialValue={watch('country')}
              setValueCallBack={(value) => setValue('country', value)}
              {...register('country')}
              options={Object.values(countries)}
              hasError={!!errors?.country?.message}
              errorMessage={errors?.country?.message}
            />
          </div>
          <ButtonGroup
            className='back-next'
            type='back-next'
            buttonOneProps={{
              type: 'submit',
              name: 'form-submit',
              loading: submitting,
              dataTestId: 'next',
              children: 'weiter',
            }}
          />
        </form>
      </FormLayout>
      <Modal
        isOpen={modalVisibility}
        onClose={() => setModalVisibility(false)}
        type='middle'
        direction='down'
      >
        <div>
          <h1 className='heading b2c'>Bitte beachte:</h1>
          <p className='content-normal'>
            Die Rechtsform, die Du zuvor gewählt hast, wurde auf der Grundlage
            der Wahl des Unternehmens aus der vorherigen Liste angepasst.
            <br />
            <br />
            Wenn diese nicht korrekt ist, klicke bitte auf zurück und korrigiere
            sie.
          </p>
          <Button
            theme='secondary'
            type='button'
            className='long margin-auto top-24'
            onClick={() => setModalVisibility(false)}
          >
            Verstanden
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={infoVisibility}
        onClose={() => setInfoVisibility(false)}
        type='middle'
        direction='down'
      >
        <div>
          <h1 className='heading b2c'>Bitte beachte:</h1>
          <p className='content-normal'>
            Für deine Rechtsform darf nur der Eigentümer selbst den Vertrag
            unterschreiben. Bevollmächtigte Unterzeichner sind nicht möglich.
          </p>
          <Button
            theme='secondary'
            type='button'
            className='long top-24 margin-auto'
            onClick={() => setInfoVisibility(false)}
          >
            Verstanden
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default CompanyAddress;
